import { deleteWidget } from "api/unity";

const deleteUnityWidget = async (id: any) => {
  const response: any = await deleteWidget(id);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default deleteUnityWidget;