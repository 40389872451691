import { useState, useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import useApp from "hooks/useApp";

const TableManager = ({ type, setKeyword, btnAction, secondaryBtnAction, children, setSelectedPage, isMapping, onMapping, searchRightSide, specialButtonText, specialButtonAction, downloadfiltereddata }: any) => {

  const { userRole } = useApp();
  const [isEditDisabled, setIsEditDisabled] = useState(false);

  useEffect(() => {
    if (userRole !== 'admin') {
      setIsEditDisabled(true);
    }
  }, [userRole]);

  const containerClasses = classNames(type === 'alarms' ? "" : "justify-end gap-2", type === 'files' ? "pb-2" : "", "flex w-full items-center rounded-md");
  const btnClasses = classNames(isEditDisabled ? "opacity-50" : "", "btn");

  const fontSize = classNames('text-sm');

  return (
    <div className={containerClasses} style={{ pointerEvents: 'visible' }}>
      <div className={classNames(!searchRightSide ? "w-full" : "", "flex gap-5")}>
        <div className="flex">
          <input
            onChange={(e: any) => setKeyword(e.target.value)}
            className="input text-sm xl:text-md 2xl:text-lg"
            type="text"
            placeholder="Search"
          />
        </div>
        {children}
      </div>
      {type === 'mapping' &&
        <div>
          <button
            className={classNames(btnClasses, 'flex items-center justify-center')}
            onClick={!isEditDisabled ? secondaryBtnAction : null}
          >
            Choose All
          </button>
        </div>
      }
      {specialButtonText &&
        <button
          className={btnClasses}
          onClick={specialButtonAction}
        >
          {specialButtonText}
        </button>
      }
      {downloadfiltereddata &&
        <button
          className={btnClasses}
          onClick={downloadfiltereddata}
        >
          {"Download Filtered Data"}
        </button>
      }
      {type !== 'noBtnAction' && type !== 'alarms' && type !== 'files' &&
        <div className="flex gap-1">
          <button
            className={btnClasses}
            onClick={() => {
              !isEditDisabled && btnAction();
              if (setSelectedPage) {
                { type !== "mapping" && (!isEditDisabled ? setSelectedPage(type) : null); }
              }
            }}
          >
            {type === "mapping" ? "Confirm" : "Add"}
          </button>
          {isMapping && <button
            className={btnClasses}
            onClick={() => { !isEditDisabled ? onMapping() : null; }}
          >
            {type === "mappedDocs" ? "Map Docs" : "Map Tags"}
          </button>}
        </div>
      }
      {type === 'alarms' &&
        <div className="flex px-2 gap-6 ml-10">
          <button
            className={btnClasses}
            onClick={!isEditDisabled ? btnAction : null}
          >
            Acknowledge All
          </button>
          <button
            className={classNames(btnClasses, 'flex items-center justify-center')}
            onClick={!isEditDisabled ? secondaryBtnAction : null}
          >
            <FiTrash2 />
            <span className="ml-1">Delete All</span>
          </button>
        </div>
      }
      {type === 'files' &&
        <div className="flex px-2 gap-2">
          <button
            className={btnClasses}
            onClick={!isEditDisabled ? btnAction : null}
          >
            Select All
          </button>
          <button
            className={classNames(btnClasses, 'flex items-center justify-center')}
            onClick={!isEditDisabled ? secondaryBtnAction : null}
          >
            <FiTrash2 />
            <span className="ml-1">Delete</span>
          </button>
        </div>
      }
    </div>
  );
};

export default TableManager;