import { CalendarIcon } from "@radix-ui/react-icons";
import { ConfigProvider, DatePicker, theme, message } from "antd";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { PrettoSlider } from "../../components/widgets/Slider/PrettoSliderDemo";
import useUnity from "hooks/UseUnity";

const StartEndDate = ({ marks }: any) => {
  const {
    projectEnddate,
    projectStartdate,
    setprojectEnddate,
    setprojectStartdate,
    setTimelineShown,
    setTimlineLoading,
  } = useUnity();

  const onenddateChange = (date: any, dateString: any) => {
    setTimlineLoading(true);
    setTimelineShown(false);
    setprojectEnddate(new Date(date));
    if (date) {
      message.info(`Selected Date: ${dateString}`);
    }
    setTimeout(() => {
      setTimelineShown(true);
      setTimlineLoading(false);
    }, 400);
  };

  const onstartdateChange = (date: any, dateString: any) => {
    setTimlineLoading(true);
    setTimelineShown(false);
    setprojectStartdate(new Date(date));
    if (date) {
      message.info(`Selected Date: ${dateString}`);
    }
    setTimeout(() => {
      setTimelineShown(true);
    }, 400);
    setTimlineLoading(false);
  };

  return (
    <div className="flex justify-center items-center w-full px-10 mt-3">
      <Box sx={{ width: "65%", marginTop: "-15px", display: "flex" }}>
        <div className="absolute w-full justify-start flex items-center ml-5 mt-5">
          <div className="-ml-32 px-4 py-1 text-lg">Start Date</div>
          <BorderGradient className="h-fit z-50">
            <ConfigProvider
              theme={{
                algorithm: theme.darkAlgorithm,
              }}
            >
              <DatePicker
                className="text-green hover:text-white"
                format="DD-MM-YYYY" // Change the date format here
                onChange={onstartdateChange}
                defaultValue={dayjs(projectStartdate)}
                suffixIcon={
                  <CalendarIcon
                    color="rgb(20 184 166)"
                    height={20}
                    width={18}
                  ></CalendarIcon>
                }
                placement="topLeft"
              ></DatePicker>
            </ConfigProvider>
          </BorderGradient>
        </div>
        <PrettoSlider
          aria-label="Timeline"
          defaultValue={99}
          marks={marks}
          min={0}
          max={99}
          style={{ color: "black" }}
        />
        <div className="absolute w-[69.8%] justify-end flex items-center -ml-[3.7rem] mt-5">
          <BorderGradient className="h-fit z-50">
            <ConfigProvider
              theme={{
                algorithm: theme.darkAlgorithm,
              }}
            >
              <DatePicker
                className="text-green hover:text-white"
                format="DD-MM-YYYY" // Change the date format here as well
                onChange={onenddateChange}
                defaultValue={dayjs(projectEnddate)}
                suffixIcon={
                  <CalendarIcon
                    color="rgb(20 184 166)"
                    height={20}
                    width={18}
                  ></CalendarIcon>
                }
                placement="topLeft"
              ></DatePicker>
            </ConfigProvider>
          </BorderGradient>
          <div className="px-2.5 ml-2 py-1 text-lg">End Date</div>
        </div>
      </Box>
    </div>
  );
};

export default StartEndDate;
