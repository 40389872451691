import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import { FiX } from "react-icons/fi";

const ReportModal = ({ setSelectedTag, setModalType }: any) => {
  return (
    <div className="z-10 h-full max-h-[87vh] w-full opacity-95">
      {/* <BorderGradient className="w-full h-max"> */}
        <div className="w-full h-max p-3 bg-dark rounded-md">
          <div className="flex justify-between">
            <h3 className="text-2xl">Report</h3>
            <button
              className="cursor-pointer bg-green rounded-md p-1"
              onClick={() => {
                setSelectedTag({ action: "", value: null });
                setModalType('');
              }
              }
            >
              <FiX size={20} />
            </button>
          </div>
        </div>
      {/* </BorderGradient> */}
    </div>
  );
};

export default ReportModal;