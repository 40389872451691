import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@/components/ui/table";
import { ConfigProvider, DatePicker, theme, message } from "antd";
import { CalendarIcon } from "@radix-ui/react-icons";
import dayjs from "dayjs";
import ColorDropdown from "components/widgets/Slider/ColorDropdown";
import { useEffect } from "react";
import { MdCheck, MdClose } from 'react-icons/md';
import { modifyPointCloud } from "api/timeline";
import { getPointCloudData } from "pages/unity/domain/getPointCloudData";
import useUnity from "hooks/UseUnity";

const PointCloudTable = () => {
  const {
    projectEnddate,
    projectStartdate,
    selectedValues,
    setSelectedValues,
    setTimelineShown,
    setTimlineLoading,
    TimelineShown
  } = useUnity();

  const onPointcloudDateChange = async (id: any, date: any, dateString: any) => {
    const currentcloud: any = selectedValues.filter((value: any) => value.id === id)[0];
    const response = await modifyPointCloud({ ...currentcloud, scannedAt: new Date(date).toISOString() });
    if (response.status === 200) {
      if (TimelineShown) {
        setTimelineShown(false);
        setTimlineLoading(true);
        setTimeout(() => {
          setTimlineLoading(false);
          setTimelineShown(true);
        }, 1000);
        getPointCloudData(setSelectedValues);
      }
      if (date) {
        message.info(`Selected Date: ${dateString}`);
      }
    }
  };

  useEffect(() => {
    getPointCloudData(setSelectedValues);
  }, []);

  const disabledDate = (current: any) => {
    const start = dayjs(projectStartdate).startOf('day');
    const end = dayjs(projectEnddate).endOf('day');
    return current && (current < start || current > end);
  };

  return (
    <div className="bg-dark overflow-auto p-3 mb-2 rounded-md h-full flex">
      <Table className="w-full border-collapse">
        <TableHeader className="text-green border-b-2">
          <TableRow className="weigh no-underline border-none">
            <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
              {" "}
              PointCloud Name
            </TableHead>
            {selectedValues[0]?.details.map((singleDetail: any, index: number) => {
              return (
                <TableHead
                  className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer text-center"
                  key={index}
                >
                  {singleDetail.type}
                </TableHead>
              );
            })}
            <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer text-center">
              Date
            </TableHead>
            <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer text-center">
              Color
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {selectedValues && selectedValues.length > 0 && selectedValues.map((value: any) => (
            <TableRow
              key={value.id}
              className="border-b border-b-lightGray hover:bg-dark duration-300"
            >
              <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
                {value.name}
              </TableHead>
              {value.details.map((singleDetail: any, index: number) => {
                return (
                  <TableCell key={index} className="text-center">
                    <span className="w-full flex justify-center items-center">
                      {singleDetail.processedOn === null ? <MdClose size={25} /> : <MdCheck size={25} />}
                    </span>
                  </TableCell>
                );
              })}
              <TableCell className="text-center">
                {" "}
                <ConfigProvider
                  theme={{
                    algorithm: theme.darkAlgorithm,
                  }}
                >
                  <DatePicker
                    className="text-green hover:text-white"
                    // aria-placeholder="input"
                    onChange={(date, dateString) =>
                      onPointcloudDateChange(value.id, date, dateString)
                    }

                    defaultValue={dayjs(new Date(value.scannedAt))}
                    suffixIcon={
                      <CalendarIcon
                        color="rgb(20 184 166)"
                        height={20}
                        width={18}
                      ></CalendarIcon>
                    }
                    placement="topLeft"
                    format="DD-MM-YYYY"
                    disabledDate={disabledDate}
                  ></DatePicker>
                </ConfigProvider>
              </TableCell>
              <TableCell className="text-center flex items-center justify-center">
                <ColorDropdown
                  id={value.id}
                ></ColorDropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div >
  );
};

export default PointCloudTable;
