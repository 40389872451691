import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import { FiX } from "react-icons/fi";
import TabsMenu from "components/shared/tabsMenu";
import { useEffect, useState } from "react";
import FileExplorer from "components/FileExplorer/FileExplorer";
import ProjectDate from "components/ProjectDate/ProjectDate";
import PointsUploader from "components/PointsUploader/PointsUploader";
import FileUploader from "components/FileUploader/FileUploader";
import classNames from "utilities/ClassNames";
import { SmallestLoader } from "components/shared/Loader";
import { processDroneImages } from "api/pointCloud";

const SettingsModal = ({ setSelectedTag, setModalType }: any) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [showUploader, setShowUploader] = useState(false);
  const [message, setMessage] = useState({ type: 'none', message: '' });
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const tabs = [
    {
      isSelected: selectedItem === 0,
      name: "File Explorer",
      onClickHandler: () => setSelectedItem(0),
    },
    {
      isSelected: selectedItem === 1,
      name: "Add Images",
      onClickHandler: () => setSelectedItem(1),
    },
    {
      isSelected: selectedItem === 2,
      name: "Main settings",
      onClickHandler: () => setSelectedItem(2),
    },
  ];

  function sendCanvasVisibility(state: number) {
    window.vuplex.postMessage(JSON.stringify({ object: "CanvasVisibility", type: "CanvasVisibility", method: "SetStatus", arguments: [state] }));
  }

  const processImages = async () => {
    setLoading(true);
    const response = await processDroneImages();
    if (response.status === 200) {
      setMessage({ type: 'success', message: 'Successfully processed drone images' });
      setLoading(false);
    }
    else {
      setMessage({ type: 'error', message: 'Could not process drone images' });
      setLoading(false);
    }
    setReload(true);
  };

  useEffect(() => {
    if (window.vuplex) {
      if (location.pathname === '/3d-dashboard') {
        sendCanvasVisibility(0);
        return () => {
          sendCanvasVisibility(1);
        };
      }
      else {
        return () => {
          sendCanvasVisibility(0);
        };
      }
    }
  }, [window.vuplex, location.pathname]);

  useEffect(() => {
    setShowUploader(false);
    setMessage({ type: 'none', message: '' });
  }, [selectedItem]);

  useEffect(() => {
    if (message.type === 'success') {
      setShowUploader(false);
    }
  }, [message]);

  return (
    <div className="z-10 h-full w-full xl:mt-4 opacity-95">
      {/* <BorderGradient className="w-full h-full opacity-95"> */}
        <div className="h-full bg-dark rounded-md overflow-hidden">
          <div className="w-full p-3 pb-0 bg-dark rounded-md h-max">
            <div className="flex justify-between">
              <div className="flex justify-center items-center gap-2">
                <h3 className="text-2xl">Settings</h3>
              </div>
              <button
                className="cursor-pointer bg-green rounded-md p-1"
                onClick={() => {
                  setSelectedTag({ action: "", value: null });
                  setModalType("");
                }}
              >
                <FiX size={20} />
              </button>
            </div>
            <div className="flex justify-between relative">
              {showUploader &&
                <div className="absolute top-28 right-0 left-0 z-50">
                  <FileUploader fileType="pointCloud" setMessage={setMessage} setShowUpload={setShowUploader} setReload={setReload} />
                </div>
              }
              <TabsMenu tabs={tabs} />
            </div>
          </div>
          {selectedItem === 0 && (
            <div className="h-full w-full">
              <FileExplorer type={'regular'} />
            </div>
          )}
          {selectedItem === 1 && (
            <div>
              <>
                <div className="flex items-center justify-start align-middle gap-2 absolute z-40 flex-wrap w-[50%] left-1">
                  <span className="flex gap-2 pl-6 items-center justify-center">
                    <button className="btn min-w-24" onClick={() => {
                      setShowUploader((prev) => !prev);
                      setMessage({ type: 'none', message: '' });
                    }}>
                      {showUploader ? 'Close' : 'Upload'}
                    </button>
                    <button className="btn" onClick={processImages}>Process</button>
                    {message.type !== 'none' && <div className={classNames(message.type === 'error' ? 'text-red' : 'text-green', 'text-lg')}>{message.message}</div>}
                  </span>
                  {loading && <SmallestLoader />}
                  <div className="h-full rounded text-md  w-full py-0.5 flex-grow flex items-start pl-6">
                    <p className="text-center p-0.5">
                      Upload images and a CSV file with coordinates in this format: <span className="font-bold">#name,x,y,z</span>
                    </p>
                  </div>
                </div>
              </>
              <PointsUploader reload={reload} setReload={setReload} />
            </div>
          )}
          {selectedItem === 2 && (
            <div className="h-[85%] w-full">
              <ProjectDate />
            </div>
          )}
        </div>
      {/* </BorderGradient> */}
    </div>
  );
};

export default SettingsModal;
