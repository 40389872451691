import React, { useEffect, useState } from "react";
import tableConfig from "./config/tableConfig";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "@/components/ui/table";
import { BsPinAngle, BsPinAngleFill } from "react-icons/bs";
import { FaExclamationTriangle } from 'react-icons/fa';
import { DataTableProps } from "./domain/types";
import { Item } from "types/common";
import classNames from "utilities/ClassNames";
import { usePins } from "hooks/usePins";
import TagsActions from "./tableActions/TagsActions/TagsActions";
import { Checkbox } from "@/components/ui/checkbox";
import { getTagsByEquipmentsId } from "api/tags";
import RoleDropdown from "pages/users/RoleDropdown";
import { getRoles } from "api/users";
import useApp from "hooks/useApp";
import { Tooltip, Typography } from '@mui/material';
import truncateText from "utilities/truncateText";

function sortItems(items: any, targetEquipmentsIds: any) {
  if (items) {
    return items.sort((a: any, b: any) => {
      const aSelected = targetEquipmentsIds.includes(a.id) || targetEquipmentsIds.includes(a.fileId);
      const bSelected = targetEquipmentsIds.includes(b.id) || targetEquipmentsIds.includes(b.fileId);

      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });
  }
  return [];
}

const DataTable: React.FC<DataTableProps> = ({ type, rows, hasPins, hasActions, onClickHandler, onActionClickHandler, setSelectedPage, setMappedTags, mappedTags, selectedPage, special, specialItems, selectedItem, hasRoles, refresh }) => {
  const { userRole } = useApp();
  const { headings, body, itemName, containerClasses, isSelectedItemShown, rowSingleTitleClasses } = tableConfig[type];
  const [processedHeadings, setProcessedHeadings] = useState(headings);
  const [rowsForEquipment, setRowsForEquipment] = useState<any>([]);
  const [mappedRows, setMappedRows] = useState<any>([]);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [sortedRows, setSortedRows] = useState(rows);
  const [selectedUnderlineItem, setSelectedUnderlineItem] = useState<any>(null);
  const [rolesData, setRolesData] = useState<any>();
  const { pinTag, unPin } = usePins();
  const tableContainerClasses = classNames(special ? "bg-dark" : "bg-black", "rounded-md h-full", containerClasses);
  const rowTitleClasses = classNames(special ? "" : "cursor-pointer", "hover:text-green p-3", rowSingleTitleClasses);

  useEffect(() => {
    const noPinsHeadings = headings.filter((single) => single.name !== 'Pinned');
    const noActionsHeadings = headings.filter((single) => single.name !== 'Actions');
    const noActionsAndPinsHeadings = noActionsHeadings.filter((single) => single.name !== 'Pinned');
    if (!hasPins && hasActions) {
      setProcessedHeadings(noPinsHeadings);
    }
    if (!hasActions && hasPins) {
      setProcessedHeadings(noActionsHeadings);
    }
    if (!hasActions && !hasPins) {
      setProcessedHeadings(noActionsAndPinsHeadings);
    }
  }, []);

  useEffect(() => {
    if (mappedTags && mappedTags.added) {
      setRowsForEquipment((prev: any) => [...prev, ...mappedTags.added]);
    }
  }, [mappedTags]);

  useEffect(() => {
    if (special) {
      const mappedItems = specialItems?.items;
      if (mappedItems) {
        const ids = mappedItems.map((single: any) => single.fileId);
        setRowsForEquipment(ids);
      }
    }
  }, [specialItems, special]);

  useEffect(() => {
    if (selectedItem) {
      setSelectedUnderlineItem(selectedItem.id);
    }
    else {
      setSelectedUnderlineItem(null);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedPage && special && type === 'tags') {
      const fetchRows = async (equipmentId: any) => {
        const response = await getTagsByEquipmentsId(equipmentId);
        if (response.status === 200) {
          const responseIds = response.data.map((item: any) => item.id);
          setRowsForEquipment(responseIds);
        }
      };
      fetchRows(selectedPage.id);
    }
  }, [rows, selectedPage]);

  useEffect(() => {
    setMappedRows(sortItems(rows, rowsForEquipment));
  }, [rows, rowsForEquipment]);

  useEffect(() => {
    if (special) {
      setSortedRows(mappedRows);
    }
    if (!special) {
      setSortedRows(rows);
    }
  }, [rowsForEquipment, rows, mappedTags, mappedRows]);

  useEffect(() => {
    if (userRole !== 'admin') {
      setIsEditDisabled(true);
    }
  }, [userRole]);

  const isSelected = (tag: any) => {
    return rowsForEquipment.some((row: any) => row === tag.id);
  };

  useEffect(() => {
    const getRolesApi = async () => {
      const resp2 = await getRoles();
      if (resp2 && resp2.status === 200) {
        setRolesData(resp2.data);
      }
    };

    getRolesApi();
  }, [rows]);

  const onClickSpecialHandler = (item: Item) => {
    if (rowsForEquipment.some((row: any) => row === item.id)) {
      if (setMappedTags) {
        if (mappedTags.added.includes(item.id)) {
          setMappedTags((prev: any) => ({
            added: [...prev.added.filter((addedItem: any) => addedItem !== item.id)],
            removed: [...prev.removed],
          }));
        }
        if (!mappedTags.added.includes(item.id)) {
          setMappedTags((prev: any) => ({
            added: [...prev.added],
            removed: [...prev.removed, item.id],
          }));
        }
      }
      const newRows = rowsForEquipment.filter((row: any) => row !== item.id);
      setRowsForEquipment(newRows);
    } else {
      if (setMappedTags) {
        if (mappedTags.removed.includes(item.id)) {
          setMappedTags((prev: any) => ({
            added: [...prev.added],
            removed: [...prev.removed.filter((removedItem: any) => removedItem !== item.id)],
          }));
        }
        if (!mappedTags.removed.includes(item.id)) {
          setMappedTags((prev: any) => ({
            added: [...prev.added, item.id],
            removed: [...prev.removed],
          }));
        }
      }
      const newRows = [...rowsForEquipment, item.id];
      setRowsForEquipment(newRows);
    }
  };

  const isFileSelected = (file: any) => {
    return specialItems.includes(file.name);
  };

  return (
    <div className={tableContainerClasses}>
      <Table className="w-full border-collapse">
        <TableHeader className={classNames(special ? "bg-dark" : "bg-black", "outline outline-1 outline-lightGray")}>
          <TableRow className="weigh no-underline border-none">
            {processedHeadings.map((heading, index) => (
              <TableHead key={index} className={classNames(heading.classes, 'font-bold text-[15px] xl:text-[17px] 2xl:text-xl')}>
                {heading.name}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedRows && sortedRows.map((item: any, rowIndex: number) => (
            <TableRow className={classNames(item.statusId === 'New' ? "bg-lightRed bg-opacity-20" : "", "border-b border-b-lightGray hover:bg-dark duration-300")} key={rowIndex}>
              <TableCell
                onClick={() => {
                  special ? onClickSpecialHandler(item) : onClickHandler(item, 'chart');
                }}
                className={classNames(
                  isSelectedItemShown ? item.id === selectedUnderlineItem ? "text-orange" : "text-green" : "", rowTitleClasses, "text-[15px] xl:text-[16px] 2xl:text-xl h-min p-2.5 2xl:p-3")}
              >
                {special ? (
                  <>
                    <Checkbox
                      checked={isSelected(item)}
                      aria-label="Select row"
                      style={{ color: isSelected(item) ? 'cyan' : '' }}
                    />
                    <span className="cursor-pointer ml-2">{item[itemName as keyof Item]}</span>
                  </>
                ) : (
                  item.isNull ? <span className="flex items-center gap-2">
                    <FaExclamationTriangle color="red" />
                    <span className="font-bold">{item[itemName]}</span>
                  </span > : <span className="font-bold">{item[itemName]}</span>
                )}
              </TableCell>
              {body.map((single, cellIndex) => {
                const value = item[single.name as keyof Item];
                const displayValue = value instanceof Date ? new Date(value) : value;
                return (
                  <TableCell key={cellIndex} className={classNames(
                    value === 'Healthy' || value === 'Open' ? 'text-limeGreen' : '',
                    value === 'Failed' || value === 'Closed' ? 'text-red' : '',
                    value === 'Warning' || value === 'Rejected' ? 'text-orange' : '',
                    single.name === 'message' ? 'text-lightBlue' : '',
                    single.classes,
                    "text-[15px] xl:text-[16px] 2xl:text-xl"
                  )}>
                    {single.name === 'name' ?
                      <Tooltip
                        title={<span style={{ whiteSpace: 'pre-wrap', maxWidth: 'none' }}>{displayValue}</span>}
                        PopperProps={{ disablePortal: true }}
                        arrow
                        placement={single.classes === 'text-center' ? undefined : 'bottom-start'}
                      >
                        <Typography noWrap>
                          {truncateText(displayValue, 30)}
                        </Typography>
                      </Tooltip>
                      :
                      displayValue
                    }
                  </TableCell>
                );
              })}
              {type === 'files' && (
                <TableCell onClick={() => onActionClickHandler(item.name, 'delete')} className="flex items-center justify-center">
                  <Checkbox
                    checked={isFileSelected(item)}
                    aria-label="Select row"
                    style={{ color: isSelected(item) ? 'cyan' : '' }}
                  />
                </TableCell>
              )}
              {hasActions && (
                <TableCell>
                  <div className="flex gap-4 w-max mx-auto">
                    {type === 'tags' && hasPins && (
                      <div className="flex justify-center cursor-pointer">
                        {item.isPinnedReact ? (
                          <BsPinAngleFill
                            size={17}
                            className={classNames(isEditDisabled ? "opacity-50" : "text-green")}
                            onClick={() => !isEditDisabled ? unPin(item.id, null, 1) : null}
                          />
                        ) : (
                          <BsPinAngle
                            size={17}
                            className={classNames(isEditDisabled ? "opacity-50" : "")}
                            onClick={() => !isEditDisabled ? pinTag(item.id, true) : null}
                          />
                        )}
                      </div>
                    )}
                    {type === 'tags' && <TagsActions type="tag" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'expressions' && <TagsActions type="expression" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'trains' && <TagsActions type="train" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'units' && <TagsActions type="unit" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'equipments' && <TagsActions type="equipment" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'tickets' && <TagsActions type="tickets" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'alarms' && <TagsActions type="alarms" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'health' && <TagsActions type="health" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'mappedDocs' && <TagsActions type="mappedDocs" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                    {type === 'docs' && <TagsActions type="docs" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                  </div>
                </TableCell>
              )}
              {hasRoles && (
                <TableCell>
                  <div>
                    <RoleDropdown
                      id={item.id}
                      currentrole={
                        item.role
                          ? item.role.charAt(0).toUpperCase() +
                          item.role.slice(1)
                          : item.role
                      }
                      rolesData={rolesData}
                      refreshhandler={refresh}
                    />
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataTable;
