import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
} from "react-icons/fi";
import useScreen from "hooks/useScreen";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import "moment-timezone";

import { useState, useEffect } from "react";
import classNames from "utilities/ClassNames";
import { MdInfoOutline } from "react-icons/md";
import useApp from "hooks/useApp";

export default function Info() {
  const { dateTime, setDateTime } = useApp();
  const { isScaled } = useScreen();

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<any>(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(null);

  useEffect(() => {
    if (selectedTime && date) {
      const newDate = date.setHours(selectedTime.h, selectedTime.m, 0);
      setDate(new Date(newDate));
      setDateTime(new Date(newDate));
    }
  }, [selectedTime]);
  return (
    <div className="relative mr-5">
      {open && (
        <div
          className="fixed z-10 inset-0 w-full h-full bg-transparent"
          onClick={() => setOpen(false)}
        />
      )}
      <div className="relative z-20">
        <Popover open={open}>
          <PopoverTrigger
            asChild
            className="text-white cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <div className="cursor-pointer hover:text-green">
              <MdInfoOutline
                size={25}
                className="lg:w-[80%] xl:w-[85%] 2xl:w-full"
              />
            </div>
          </PopoverTrigger>
          <PopoverContent
            className={classNames(
              isScaled ? "text-xl" : "text-base",
              "w-auto bg-black mt-1 p-0"
            )}
            align="end"
          >
            <div className="lg:w-[600px] lg:h-[500px] xl-2xl:w-[650px] xl-2xl:h-[550px] 2xl:w-[700px] 2xl:h-[560px] overflow-hidden overflow-y-scroll pl-3 pt-3 pb-3">
              <h1 className="text-green font-bold lg:text-lg 2xl:text-2xl">
                Key Guide
              </h1>
              <div className="mt-1">
                {/* titles */}
                <div className="flex">
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg underline">
                    Action
                  </div>
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg underline flex justify-center lg:mr-3 2xl:mr-0">
                    Keyboard/Mouse
                  </div>
                </div>
                {/* one */}
                <div className="flex mt-1 justify-between">
                  {/* First part: Horizontal Movement */}
                  <div className="flex-1 flex gap-5">
                    <div className="flex flex-nowrap w-48 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Horizontal Movement</p>
                    </div>
                  </div>

                  {/* Second part: a and d buttons */}
                  <div className="flex-1 flex gap-3 justify-center mr-5">
                    <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      a
                    </div>
                    <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      d
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg flex justify-start mr-9">
                    <p>a - left, d - right</p>
                  </div>
                </div>

                {/* two */}
                <div className="flex mt-1 justify-between">
                  {/* First part: Empty div */}
                  <div className="flex-1">
                    <div className="w-48"></div>
                  </div>

                  {/* Second part: Chevron buttons */}
                  <div className="flex-1 flex gap-3 justify-center mr-5">
                    <div className="lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      <FiChevronLeft />
                    </div>
                    <div className="lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      <FiChevronRight />
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg mb-1 flex justify-start mr-9">
                    <p>← - left, → - right</p>
                  </div>
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />

                {/* three */}
                <div className="flex mt-2 justify-between">
                  {/* First part: Vertical Movement */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-40 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Vertical Movement</p>
                    </div>
                  </div>

                  {/* Second part: w and s buttons */}
                  <div className="flex-1 flex gap-3 justify-center -ml-6 2xl:-ml-12">
                    <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      w
                    </div>
                    <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      s
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg flex justify-start">
                    <p>w - forward, s - backward</p>
                  </div>
                </div>

                {/* four */}
                <div className="flex mt-1 justify-between">
                  {/* First part: Empty space */}
                  <div className="flex-1">
                    <div className="w-40"></div>
                  </div>

                  {/* Second part: Up and Down chevron buttons */}
                  <div className="flex-1 flex gap-3 justify-center -ml-6 2xl:-ml-12">
                    <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      <FiChevronUp />
                    </div>
                    <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      <FiChevronDown />
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg mb-1 flex justify-start">
                    <p>↑ - forward, ↓ - backward</p>
                  </div>
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-0.5" />

                {/* five */}
                <div className="flex mt-2 justify-between">
                  {/* First part: Fly text */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-40 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Fly</p>
                    </div>
                  </div>

                  {/* Second part: f button */}
                  <div className="flex-1 flex gap-3 mb-1 justify-center mr-6">
                    <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                      f
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg flex justify-start lg:mr-5 2xl:mr-7">
                    <p>f - to fly</p>
                  </div>
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />

                {/* six */}
                <div className="flex mt-1 justify-between">
                  {/* First part: Lock Flying */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-40 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Lock Flying</p>
                    </div>
                  </div>

                  {/* Second part: l button */}
                  <div className="flex-1 flex gap-3 justify-center lg:-ml-4 2xl:-ml-10">
                    <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md mb-1">
                      l
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg flex justify-center lg:mr-7 2xl:mr-2">
                    <p>l - to lock the flying height</p>
                  </div>
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />

                {/* seven */}
                <div className="flex mt-1 justify-between">
                  {/* First part: Medium Speed */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-48 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Medium Speed</p>
                    </div>
                  </div>

                  {/* Second part: Movement + Left Shift */}
                  <div className="flex-1 -ml-7 flex gap-1 lg:text-xs xl-2xl:text-md 2xl:text-lg justify-center">
                    <p>Movement + </p>
                    <div className="lg:text-xs 2xl:text-md px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                      Left Shift
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg flex justify-start lg:-mr-2 2xl:mr-1">
                    <p>Movement key + Left Shift</p>
                  </div>
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />

                {/* eight */}
                <div className="flex mt-1 justify-between">
                  {/* First part: High Speed */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-44 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>High Speed</p>
                    </div>
                  </div>

                  {/* Second part: Movement + Right Shift */}
                  <div className="flex-1 flex justify-center gap-1 lg:text-xs xl-2xl:text-md 2xl:text-lg -ml-7">
                    <p>Movement + </p>
                    <div className="lg:text-xs 2xl:text-md px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                      Right Shift
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg flex justify-start lg:-mr-2 2xl:mr-1">
                    <p>Movement key + Right Shift</p>
                  </div>
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />

                {/* nine */}
                <div className="flex mt-1 justify-between">
                  {/* First part: Tags */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-40 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Tags</p>
                    </div>
                  </div>

                  {/* Second part: Mouse and Left Click */}
                  <div className="flex-1 flex w-full gap-1 justify-center">
                    <div className="flex items-center gap-1 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Mouse</p>
                      <FiChevronRight />
                    </div>
                    <div className="lg:text-xs 2xl:text-md px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                      Left Click
                    </div>
                  </div>

                  {/* Third part: Placeholder */}
                  <div className="w-56 2xl:w-64" />
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />

                {/* ten */}
                <div className="flex flex-1 mt-1 justify-between">
                  {/* First part: Expression LookUp */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-48 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Expression LookUp</p>
                    </div>
                  </div>

                  {/* Second part: Mouse and Right Click */}
                  <div className="flex-1 flex w-44 gap-1 justify-center">
                    <div className="flex items-center gap-1 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Mouse</p>
                      <FiChevronRight />
                    </div>
                    <div className="lg:text-xs 2xl:text-md px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                      Right Click
                    </div>
                  </div>

                  {/* Third part: Placeholder */}
                  <div className="w-56 2xl:w-64" />
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />

                {/* eleven */}
                <div className="flex flex-1 mt-1 justify-between">
                  {/* First part: Release cursor */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-32 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Release cursor</p>
                    </div>
                    <div className="flex gap-1 lg:text-xs 2xl:text-md w-44 justify-end">
                      <div className="px-0.5 h-7 w-16 grid place-content-center bg-green text-white rounded-md">
                        Esc
                      </div>
                    </div>
                  </div>

                  {/* Third part: Instructions */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg flex justify-center lg:mr-20 2xl:mr-7">
                    <p>Escape Key</p>
                  </div>
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />

                {/* seven */}
                <div className="flex flex-1 mt-1 justify-between">
                  {/* First part: Lock cursor */}
                  <div className="flex-1 flex gap-5">
                    <div className="w-32 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                      <p>Lock cursor</p>
                    </div>
                    <div className="flex gap-1 w-44 2xl:w-60 ml-10 justify-center">
                      <div className="flex items-center gap-1 lg:text-xs xl-2xl:text-md 2xl:text-lg">
                        <p>Mouse</p>
                        <FiChevronRight />
                      </div>
                      <div className="lg:text-xs 2xl:text-md px-1 h-7 mt-1 grid place-content-center bg-green text-white rounded-md">
                        Double Click
                      </div>
                    </div>
                  </div>

                  {/* Third part: Instruction */}
                  <div className="flex-1 lg:text-xs xl-2xl:text-md 2xl:text-lg flex justify-center mr-5 p-1 ml-1 w-full">
                    <p>Click anywhere in the 3D</p>
                  </div>
                </div>

                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-1" />
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
