import classNames from "utilities/ClassNames";
import { SmallLoader, SmallestLoader } from "components/shared/Loader";
import { useEffect, useState } from "react";
import { FiX, FiChevronDown } from "react-icons/fi";
import { Popover, PopoverTrigger, PopoverContent } from "@radix-ui/react-popover";
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem } from "cmdk";
import TextOverflow from "utilities/TextOverflow";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@/components/ui/table";
import { getExpressionByEquipment, getExpressionByUnit, getExpressionById } from "api/expression";
import { getTagsByEquipmentsId } from "api/tags";
import { getEquipmentsByUnitId } from "api/equipments";
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createWidget, updateWidget } from "api/unity";
import Modal from "components/modal/Modal";
import { SelectedItem } from "types/common";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

const Widget = ({
  units,
  equipments,
  widgetData,
  setWidgetData,
  widgetModalMode,
  setWidgetModalMode
}: any) => {

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openSelectUnit, setOpenSelectUnit] = useState(false);
  const [selectUnitLabel, setSelectUnitLabel] = useState("");
  const [selectEquipmentLabel, setSelectEquipmentLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [submittingForm, setSubmittingForm] = useState(false);
  const [openSelectEquipment, setOpenSelectEquipment] = useState(false);
  const [allEquipments, setAllEquipments] = useState<any>(equipments);
  const [showModelId, setShowModelId] = useState<any>(false);
  const [allExpressions, setAllExpressions] = useState<any[]>([]);
  const [allTags, setAllTags] = useState<any>([]);
  const [processedWidgetItems, setProcessedWidgetItems] = useState<any>([]);
  const [selectedExpressions, setSelectedExpressions] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [isAddingText, setIsAddingText] = useState(false);
  const [newText, setNewText] = useState('');
  const [tag, setTag] = useState<SelectedItem>({ action: '', value: '' });

  const closeHandler = () => {
    setWidgetData({
      unitId: null,
      equipmentId: null,
      widgets: [],
      name: "",
      desc: "",
      x: 0,
      y: 0,
      z: 0,
    });
    setWidgetModalMode('none');
  };
  const deleteHandler = () => {
    setTag({
      action: 'delete',
      value: widgetData
    });
  };

  useEffect(() => {
    if (widgetModalMode === 'modify') {
      setEditMode(true);
    }
    else {
      setEditMode(false);
    }
  }, [widgetModalMode]);

  useEffect(() => {
    setSelectEquipmentLabel("");
  }, [selectUnitLabel]);

  useEffect(() => {
    const tags: any = [];
    const expressions: any = [];
    processedWidgetItems.filter((item: any) => item !== undefined).forEach((widgetItem: any) => {
      if (widgetItem.data.type === 'exp') {
        expressions.push(widgetItem.id);
      }
      if (widgetItem.data.type === 'tag') {
        tags.push(widgetItem.id);
      }
    });
    setSelectedTags(tags);
    setSelectedExpressions(expressions);
  }, [processedWidgetItems]);

  useEffect(() => {
    const fetchData = async (id: any, fetchFunction: any, setData: any) => {
      try {
        const response = await fetchFunction(id);
        if (response.status === 200) {
          setData(response.data);
        } else {
          setErrorMessage(response.data);
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    };
    if (widgetData.unitId !== null && widgetData.equipmentId === null) {
      fetchData(widgetData.unitId, getExpressionByUnit, setAllExpressions);
    }

    if (widgetData.equipmentId !== null) {
      fetchData(widgetData.equipmentId, getTagsByEquipmentsId, setAllTags);
      fetchData(widgetData.equipmentId, getExpressionByEquipment, setAllExpressions);
    }
  }, [widgetData]);

  const moveUp = (index: number) => {
    if (index === 0) return; // Prevent moving the first item up
    const newItems = [...widgetData.widgets];
    [newItems[index - 1], newItems[index]] = [newItems[index], newItems[index - 1]];
    setWidgetData((prevState: any) => ({
      ...prevState,
      widgets: newItems
    }));
  };

  const moveDown = (index: number) => {
    if (index === widgetData.widgets.length - 1) return;
    const newItems = [...widgetData.widgets];
    [newItems[index + 1], newItems[index]] = [newItems[index], newItems[index + 1]];
    setWidgetData((prevState: any) => ({
      ...prevState,
      widgets: newItems
    }));
  };

  const processWidgets = async (widgets: any) => {
    const promises = widgets.map(async (widget: any) => {
      if (widget.type === 'exp') {
        const response = await getExpressionById(widget.value);
        if (response.status === 200) {
          return {
            data: widget,
            name: response.data.name,
            id: response.data.id,
          };
        }
      }
      if (widget.type === 'text') {
        return {
          data: widget,
          name: widget.value,
          id: null,
        };
      }
      if (widget.type === 'tag') {
        const selectedTag = allTags.find((tag: any) => tag.id === Number(widget.value));
        if (selectedTag) {
          return {
            data: widget,
            name: selectedTag.name,
            id: selectedTag.id
          };
        }
      }
    });

    const results = await Promise.all(promises);
    return results;
  };

  useEffect(() => {
    const allWidgets = widgetData.widgets;
    if (allWidgets.length > 0) {
      processWidgets(allWidgets).then(results => {
        setProcessedWidgetItems(results);
      });
    }
    else {
      setProcessedWidgetItems([]);
    }
  }, [widgetData, allTags]);

  useEffect(() => {
    if (widgetData.unitId && units) {
      const selectedUnit = units.find((item: any) => item.id === widgetData.unitId);
      setSelectUnitLabel(selectedUnit.name);
    }
    if (widgetData.equipmentId && equipments) {
      const selectedEquipment = equipments.find((item: any) => item.id === widgetData.equipmentId);
      setSelectEquipmentLabel(selectedEquipment.name);
    }
  }, [units, equipments, widgetData]);

  useEffect(() => {
    (async () => {
      if (widgetData.unitId) {
        const result = await getEquipmentsByUnitId(widgetData.unitId);
        setAllEquipments(result.data);
      }
    })();
  }, [widgetData]);

  const handleClick = (data: any, e: any) => {
    e.stopPropagation();
    if (data.expression) {
      const expressionId = data.id;

      if (!selectedExpressions.includes(expressionId)) {
        setWidgetData((prevState: any) => ({
          ...prevState,
          widgets: [
            ...prevState.widgets,
            {
              attribute: "string",
              type: "exp",
              value: expressionId
            }
          ]
        }));
      } else {
        setWidgetData((prevState: any) => ({
          ...prevState,
          widgets: prevState.widgets.filter(
            (widget: any) => widget.type !== "exp" || widget.value !== expressionId
          )
        }));
      }
    }

    else {
      const tagId = data.id;
      if (!selectedTags.includes(tagId)) {
        setWidgetData((prevState: any) => ({
          ...prevState,
          widgets: [
            ...prevState.widgets,
            {
              attribute: "string",
              type: "tag",
              value: tagId
            }
          ]
        }));
      } else {
        setWidgetData((prevState: any) => ({
          ...prevState,
          widgets: prevState.widgets.filter(
            (widget: any) => widget.type !== "tag" || widget.value.toString() !== tagId.toString()
          )
        }));
      }
    }
  };

  const handleAddText = () => {
    if (newText.trim()) {
      setWidgetData((prevState: any) => ({
        ...prevState,
        widgets: [
          ...prevState.widgets,
          {
            attribute: "string",
            type: "text",
            value: newText
          }
        ]
      }));
      setNewText('');
    }
    setIsAddingText(false);
  };

  const handleRemoveText = (item: any) => {
    setWidgetData((prevState: any) => ({
      ...prevState,
      widgets: prevState.widgets.filter(
        (widget: any) => {
          if (widget.type === 'text') {
            return widget.value !== item.name;
          }
          return true;
        }
      )
    }));
  };

  const widgetHandler = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const updatedWidgetData = {
      ...widgetData,
      widgets: widgetData.widgets.map((widget: any) => ({
        ...widget,
        value: String(widget.value),
      })),
    };

    const response = editMode ? await updateWidget(updatedWidgetData) : await createWidget(updatedWidgetData);


    if (response.status === 200) {
      setWidgetData({
        unitId: null,
        equipmentId: null,
        widgets: [],
        name: "",
        desc: "",
        x: 0,
        y: 0,
        z: 0,
      });
      setWidgetModalMode('none');
    } else {
      setErrorMessage(response.data.Validations[0]);
    }
    setLoading(false);
  };

  const refresh = async () => {
    setWidgetModalMode('none');
  };

  return (
    <>
      <div className="fixed inset-0 z-10 w-full h-screen">
        <div
          className={classNames("h-full",
            "absolute z-20 inset-0 w-full bg-black/70"
          )}
        />

        <div className={classNames("top-[57%]", "w-full h-auto rounded-md z-30 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[1200px] opacity-95")}>
          <div className="bg-dark p-3 w-full h-full rounded-md">
            <div className="flex justify-between">
              <span className="flex justify-center items-center gap-2">
                <h1 className="text-2xl font-bold text-green">
                  Widget Configuration
                </h1>
                {loading && (
                  <div>
                    <SmallestLoader />
                  </div>
                )}
              </span>
              <div
                className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                onClick={closeHandler}
              >
                <FiX size={20} />
              </div>
            </div>
            {/* Content */}
            <div className="mt-1">
              <form className="mt-0" onSubmit={widgetHandler}>
                <div className="mt-0">
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    name="name"
                    placeholder="Name"
                    type="text"
                    required={true}
                    className="input mt-1"
                    value={widgetData.name}
                    onChange={(e: any) => setWidgetData((prevData: any) => ({ ...prevData, name: e.target.value }))}
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="desc">Description</label>
                  <textarea
                    rows={5}
                    id="desc"
                    name="desc"
                    placeholder="Description"
                    className="input mt-1 max-h-[35vh] h-9"
                    value={widgetData.desc}
                    onChange={(e: any) => setWidgetData((prevData: any) => ({ ...prevData, desc: e.target.value }))}
                  />
                </div>

                <div className="flex items-center gap-3 mt-3 mb-2">
                  {/* units */}
                  <div className="w-1/2 relative">
                    <div className="mb-2">Unit</div>
                    <Popover open={openSelectUnit} onOpenChange={setOpenSelectUnit} >
                      <PopoverTrigger asChild>
                        <div className="w-full text-sm whitespace-nowrap h-9 px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black">
                          {selectUnitLabel && selectUnitLabel !== ""
                            ? TextOverflow(selectUnitLabel, 27)
                            : "Select Unit"}
                          <FiChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-[580px] p-0 bg-gray command z-50 border-1 border-white outline outline-1 outline-gray"
                        sideOffset={4}
                        align="start"
                      >
                        <Command className="bg-gray w-full border-1 border-white">
                          <CommandInput
                            placeholder="Search"
                            className="bg-gray border-b-slate w-full pl-1 outline outline-gray"
                          />
                          <CommandEmpty>No units found.</CommandEmpty>
                          {units && (
                            <CommandGroup className="bg-gray border-slate h-[195px] overflow-y-scroll">
                              <div>
                                {units.map((item: any) => (
                                  <CommandItem
                                    className="pl-3 cursor-pointer"
                                    key={item.id}
                                    onSelect={() => {
                                      setWidgetData((prevData: any) => ({ ...prevData, unitId: item.id }));
                                      setSelectUnitLabel(item.name);
                                      setWidgetData((prevState: any) => ({
                                        ...prevState,
                                        equipmentId: null,
                                        widgets: []
                                      }));
                                      setAllTags([]);
                                      setSelectEquipmentLabel('');
                                      setErrorMessage('');
                                      setSubmittingForm(false);
                                      setOpenSelectUnit(false);
                                    }}
                                  >
                                    {item.name}
                                  </CommandItem>
                                ))}
                              </div>
                            </CommandGroup>
                          )}
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>

                  {/* equipments */}
                  <div className="w-1/2">
                    <div className="mb-2">Equipment</div>
                    <Popover
                      open={openSelectEquipment}
                      onOpenChange={setOpenSelectEquipment}
                    >
                      <PopoverTrigger asChild>
                        <div className="w-full text-sm whitespace-nowrap h-9 px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black">
                          {selectEquipmentLabel && selectEquipmentLabel !== ""
                            ? TextOverflow(selectEquipmentLabel, 27)
                            : "Select Equipment"}
                          <FiChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-[580px] p-0 bg-black command z-50 border-1 border-white outline outline-1 outline-gray"
                        sideOffset={4}
                        align="start"
                      >
                        <Command className="bg-gray border-slate">
                          <CommandInput
                            placeholder="Search"
                            className="bg-gray border-b-slate w-full pl-1 outline outline-gray"
                          />
                          <CommandEmpty>No equipments found.</CommandEmpty>
                          {allEquipments && (
                            <CommandGroup className="bg-gray border-slate h-[230px] overflow-hidden overflow-y-scroll">
                              <div>
                                {allEquipments &&
                                  allEquipments.map((item: any) => (
                                    <CommandItem
                                      className="pl-3 cursor-pointer"
                                      key={item.id}
                                      onSelect={(currentValue) => {
                                        setWidgetData((prevData: any) => ({ ...prevData, equipmentId: item.id }));
                                        setSelectEquipmentLabel(item.name);
                                        setWidgetData((prevState: any) => ({
                                          ...prevState,
                                          widgets: []
                                        }));
                                        setErrorMessage('');
                                        setSubmittingForm(false);
                                        setOpenSelectEquipment(false);
                                      }}
                                    >
                                      {item.name}
                                    </CommandItem>
                                  ))}
                              </div>
                            </CommandGroup>
                          )}
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>

                <>
                  <div className="flex pb-2">
                    <span className="ml-1 flex-1 font-bold text-[15px] xl:text-[17px]">Expressions</span>
                  </div>
                  <div className="flex gap-5">
                    <div className="flex-1">
                      <Table className="min-h-36 max-h-36 overflow-y-auto">
                        <TableHeader>
                          <TableRow>
                            <TableHead className="text-[14px] xl:text-[16px] cursor-pointer">
                              Name
                            </TableHead>
                            <TableHead className="text-[14px] xl:text-[16px] cursor-pointer text-center">
                              Actions
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {allExpressions.map((expression, index) => {
                            return (
                              <TableRow key={index} className="text-[14px] xl:text-[16px]">
                                <TableCell>{expression.name}</TableCell>
                                <TableCell className="">
                                  <button className="flex justify-center items-center w-full h-full" type='button' onClick={(e) => handleClick(expression, e)}>
                                    {selectedExpressions.includes(expression.id) ? <FaMinus /> : <FaPlus />}
                                  </button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {
                        allTags.length > 0 &&
                        <>
                          <span className="ml-1 font-bold text-[15px] xl:text-[17px]">Tags</span>
                          <Table className="min-h-36 max-h-36 overflow-y-auto">
                            <TableHeader>
                              <TableRow>
                                <TableHead className="text-[14px] xl:text-[16px] cursor-pointer">
                                  Name
                                </TableHead>
                                <TableHead className="text-[14px] xl:text-[16px] cursor-pointer">
                                  Tag Name
                                </TableHead>
                                <TableHead className="text-[14px] xl:text-[16px] cursor-pointer text-center">
                                  Actions
                                </TableHead>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {allTags.map((tag: any, index: number) => {
                                return (
                                  <TableRow key={index} className="border-none text-[14px] xl:text-[16px]">
                                    <TableCell>{tag.name}</TableCell>
                                    <TableCell>{tag.tagNameIdentifier}</TableCell>
                                    <TableCell className="">
                                      <button className="flex justify-center items-center w-full h-full" type='button' onClick={(e) => handleClick(tag, e)}>
                                        {selectedTags.includes(tag.id) ? <FaMinus /> : <FaPlus />}
                                      </button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </>
                      }
                    </div>
                    <div className="flex-1 flex rounded-xl items-center justify-center">
                      <div className="max-h-[28vh] w-[90%] bg-green bg-opacity-15 p-2 rounded-xl">
                        <Table className="mb-2 rounded-md">
                          <TableHeader className="z-40">
                            <TableRow>
                              <TableHead className="text-[14px] xl:text-[16px] cursor-pointer">
                                Name
                              </TableHead>
                              <TableHead className="text-[14px] xl:text-[16px] cursor-pointer">
                                Type
                              </TableHead>
                              <TableHead className="text-[14px] xl:text-[16px] cursor-pointer text-center">
                                Actions
                              </TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody className="z-30">
                            {processedWidgetItems.filter((item: any) => item !== undefined).map((item: any, index: number) => {
                              return (
                                <TableRow key={index} className="border-none text-[14px] xl:text-[16px]">
                                  <TableCell className={item.data.type === 'text' ? "text-blue" : ""}>{item.name}</TableCell>
                                  <TableCell>{item.data.type}</TableCell>
                                  <TableCell className="flex justify-center items-center">
                                    <div className="w-max min-w-16 flex items-center justify-start gap-4">
                                      <span className="flex gap-2">
                                        <button type='button' onClick={() => moveUp(index)}>
                                          <FaArrowUp />
                                        </button>
                                        <button type='button' onClick={() => moveDown(index)}>
                                          <FaArrowDown />
                                        </button>
                                      </span>
                                      {item.data.type === 'text' &&
                                        <button className="flex justify-center items-center" type='button' onClick={() => handleRemoveText(item)}>
                                          <FaMinus />
                                        </button>
                                      }
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        <div className="mt-2">
                          {widgetData.unitId !== null && isAddingText ? (
                            <div className="flex gap-2 items-center">
                              <input
                                type="text"
                                onChange={(e) => setNewText(e.target.value)}
                                className="input"
                                placeholder="Enter text"
                              />
                              <button onClick={handleAddText} className="p-1 bg-blue-500 text-white rounded" type='button'>Add</button>
                              <button onClick={() => setIsAddingText(false)} className="p-1 bg-red-500 text-white rounded" type='button'>Cancel</button>
                            </div>
                          ) : (
                            <button onClick={() => setIsAddingText(true)} className="flex gap-1 items-center justify-start border border-white rounded-md w-max p-1" type='button'>
                              <span>Add text item</span>
                              <FaPlus />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>

                {errorMessage.length > 0 && <span className="text-red">{errorMessage}</span>}

                <div className="flex flex-row-reverse mt-5 gap-5">
                  <input
                    name="create"
                    id="create"
                    key="create"
                    value={editMode ? "Update" : "Create"}
                    type="submit"
                    className="btn btn-fill btn-primary"
                    disabled={submittingForm}
                  />
                  {editMode && (
                    <input
                      name="delete"
                      id="delete"
                      key="delete"
                      value="Delete"
                      type="button"
                      onClick={deleteHandler}
                      className="btn"
                    />
                  )}
                  {submittingForm && !errorMessage.length && <SmallLoader />}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal page='widgets' selectedTag={tag} setSelectedTag={setTag} isLoading={loading} refresh={refresh} />
    </>
  );
};

export default Widget;