import DataTable from "components/DataTable/DataTable";
import fileDataProcessor from "./utils/fileDataProcessor";
import { useState, useEffect, useMemo } from "react";
import { getFilesData, getFileByPath, deleteExplorerFiles } from "api/fileManager";
import { MdArrowBack } from 'react-icons/md';
import Loader, { SmallestLoader } from "components/shared/Loader";
import TableManager from "components/TableManager/TableManager";
import Debounce from "utilities/Debounce";
import { PreviewFiles } from "components/shared/PreviewFiles";
import DangerModal from "components/modal/DangerModal/DangerModal";

const sortItems = ({ items, key, isAscending = true }: any) => {
  if (key === '') {
    return items;
  }
  return items.sort((a: any, b: any) => {
    if (a[key] === null || a[key] === undefined) return isAscending ? -1 : 1;
    if (b[key] === null || b[key] === undefined) return isAscending ? 1 : -1;

    if (key === 'size') {
      return isAscending ? a[key] - b[key] : b[key] - a[key];
    }

    if (key === 'created') {
      const dateA: any = new Date(a[key]);
      const dateB: any = new Date(b[key]);
      return isAscending ? dateA - dateB : dateB - dateA;
    }

    const itemA = a[key].toString().toUpperCase();
    const itemB = b[key].toString().toUpperCase();
    if (itemA < itemB) return isAscending ? -1 : 1;
    if (itemA > itemB) return isAscending ? 1 : -1;
    return 0;
  });
};

const FileExplorer = ({ type, reload, setReload }: any) => {
  const [parent, setParent] = useState<any>(type === 'pointsUpload' ? '/raw_drone_image' : null);
  const [fileData, setFileData] = useState<any>([]);
  const [chosenFile, setChosenFile] = useState<any>(null);
  const [processedData, setProcessedData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword, setDebouncedKeyword] = useState<any>('');
  const [filter, setFilter] = useState({ filter: '', isDescending: true });
  const [fileName, setFileName] = useState<any>(null);
  const [fileType, setFileType] = useState<any>(null);
  const [filePreviewData, setFilePreviewData] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [tag, setTag] = useState<any>({
    action: "",
    value: null,
  });

  useEffect(() => {
    if (type === 'pointsUpload') {
      setParent('raw_drone_image');
    }
  }, [type]);

  useEffect(() => {
    if (!isModalOpen) {
      setFilePreviewData(null);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (fileName !== null && isModalOpen) {
      const getPreviewData = async () => {
        const response = await getFileByPath({
          path: parent,
          fileName: fileName,
        });
        if (response.status === 200) {
          setFilePreviewData(response.data);
          setIsLoading(false);
        }
      };
      getPreviewData();
    }
  }, [fileName, isModalOpen]);

  const getData = async () => {
    const response = await getFilesData(parent);
    if (response.status === 200) {
      setFileData(response.data);
      setIsLoading(false);
    }
  };

  const refresh = async () => {
    setReload(true);
  };

  useEffect(() => {
    getData();
  }, [parent, chosenFile]);

  useEffect(() => {
    if (reload) {
      getData();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    let sortedItems = sortItems({ items: fileData.contents, key: filter.filter });
    if (!filter.isDescending) {
      sortedItems = [...sortedItems].reverse();
    }
    if (fileData.contents) {
      setProcessedData(fileDataProcessor(sortedItems));
    }
  }, [fileData, filter]);

  const handleClick = async (e: any) => {
    setIsLoading(true);

    if (e.type === 0) {
      setChosenFile(e.name);
      setParent((prev: any) => (prev ? `${prev}/${e.name}` : `/${e.name}`));
    } else {
      const extension = e.name.split('.').pop()?.toLowerCase();
      const supportedTypes: { [key: string]: string } = {
        pdf: 'pdf',
        png: 'png',
        jpg: 'png',
        txt: 'txt',
        csv: 'csv'
      };

      const fileType = supportedTypes[extension!] || 'notSupported';

      if (fileType !== 'notSupported') {
        setFileType(fileType);
        setFileName(e.name);
        setIsModalOpen(true);
      } else {
        setFileType('notSupported');
        setIsLoading(false);
      }
    }
  };

  const handleClickOutside = () => {
    setTag({ action: "", value: null });
  };

  const onClickBack = () => {
    setIsLoading(true);
    setParent(fileData.navigateBackArg);
    setChosenFile(getLastItem(fileData.navigateBackArg));
  };

  const onActionClickHandler = (item: any, actionName: any) => {
    if (selectedFiles.includes(item)) {
      setSelectedFiles(selectedFiles.filter((selectedItem: any) => selectedItem !== item));
    } else {
      setSelectedFiles([...selectedFiles, item]);
    }
  };

  function getLastItem(path: any) {
    if (path.length === '') {
      return '';
    }
    const items = path.split('/');
    return items[items.length - 1];
  }

  const rows = useMemo(() => {
    let items = processedData;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = processedData?.filter((item: any) => {
        const hasName = item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword;
        return hasName;
      });
    }
    return items;
  }, [processedData, debouncedKeyword]);

  const selectAllItems = () => {
    const allItems = rows.map((single: any) => single.name);
    if (allItems.length === selectedFiles.length) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(allItems);
    }
  };

  const deleteItem = async () => {
    const deleteData = selectedFiles.map((item: any) => {
      return {
        path: parent,
        fileName: item,
      };
    });
    setTag({ action: "delete", value: deleteData });
  };

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  return (
    <>
      <div className=" w-full relative">
        <div className="px-4 pb-2 flex">
          <TableManager
            type="files"
            setKeyword={setKeyword}
            searchRightSide={true}
            btnAction={selectAllItems}
            secondaryBtnAction={deleteItem}
          />
        </div>
        {type === "regular" && (
          <div className="absolute left-4 top-1 flex gap-2 items-center justify-center">
            <button
              className="flex justify-center items-center gap-1"
              onClick={onClickBack}
            >
              {parent && <MdArrowBack size={25} />}
              {isLoading && <SmallestLoader />}
            </button>
            <div className="text-xl text-green">
              {parent ? `...${parent}` : ".../"}
            </div>
          </div>
        )}
        {
          isModalOpen && fileType !== 'notSupported' &&
          <div className='absolute w-full flex items-center justify-center xl:h-[75vh] h-[74vh] -top-[120px]'>
            {isLoading && <Loader />}
            {filePreviewData && <PreviewFiles data={filePreviewData} fileName={fileName} type='fileExplorer' fileType={fileType} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
          </div>
        }
        <div className="h-[54vh] overflow-auto bg-black mt-4">
          <DataTable
            type="files"
            hasActions={false}
            hasPins={false}
            rows={rows}
            onClickHandler={handleClick}
            onActionClickHandler={onActionClickHandler}
            specialItems={selectedFiles}
            setFilter={setFilter}
            filter={filter}
          />
        </div>
      </div>
      {tag.action === 'delete' && <div className="fixed inset-0 mt-[1.5%] w-[99%] z-10 ml-1 bg-dark bg-opacity-45 flex justify-center items-center">
        <div
          onClick={handleClickOutside}
          className="h-full absolute z-20 inset-0 w-full"
        />
        <div className={"h-auto mx-auto p-0.5 rounded-md z-50 absolute w-[20%] min-w-[300px]"}>
          <DangerModal type="delete" page="files" isLoading={isLoading} refresh={refresh} selectedTag={tag} setSelectedTag={setTag} />
        </div>
      </div>}
    </>
  );
};

export default FileExplorer;