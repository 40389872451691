import { getWidgets, updateWidgets } from "api/dashboard";
import { DynamicDashboardAPI } from "api/types/apiTypes";
import { getUserInfo } from "api/user";
import Loader from "components/shared/Loader";
import moment from "moment";
import ErrorPage from "pages/ErrorPage";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { BrowserRouter as Router } from "react-router-dom";

export const AppContext = createContext<any | null>(null);

const parseJwt = (token: any) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

const initialWidgets1 = [
  { id: "4390", tabs: [], position: 1 },
  { id: "8e65", tabs: [], position: 2 },
  { id: "33ec", tabs: [], position: 3 },
  { id: "ab5f", tabs: [], position: 4 },
  { id: "df7c", tabs: [], position: 5 },
];

const initialWidgets2 = [
  { id: "4370", tabs: [], position: 1 },
  { id: "8j65", tabs: [], position: 2 },
  { id: "33fc", tabs: [], position: 3 },
  { id: "ar5f", tabs: [], position: 4 },
  { id: "df3c", tabs: [], position: 5 },
];

export const AppProvider = ({ children }: { children: any }) => {
  const [isLoggedIn, setIsLoggedIn] = useState("loggedIn");
  const [user, setUser] = useState<any>(null);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [skype, setSkype] = useState();
  const [id, setId] = useState();
  const [token, setToken] = useState<any>(null);
  const [role, setRole] = useState<any>(null);
  const [dateTime, setDateTime] = useState<any>(null);
  const [userRole, setuserRole] = useState<any>(null);

  const [isScaled, setIsScaled] = useState<any>(null);
  const defaultTimeZone = "Asia/Kuwait";
  const userTimeZone = user?.ianaTimeZone || defaultTimeZone;
  const formatter = "YYYY-MM-DD";
  const timeZoneTime = () => {
    const localTime = new Date();
    const momentTime = moment(localTime).tz(userTimeZone);

    return new Date(momentTime.format(formatter));
  };

  const [dashboardWidgets, setDashboardWidgets] = useState<any>({
    selectedType: "init",
    widget1: initialWidgets1,
    widget2: initialWidgets2,
    widget3: [],
  });
  const [dashboardWidgetsLoading, setDashboardWidgetsLoading] = useState(true);

  const updateDashboardWidgets = async (data: any) => {
    setDashboardWidgetsLoading(true);
    const response: any = await updateWidgets({ dataJson: JSON.stringify(data) });
    if (response && response.status === 200) {
      getDashboardWidgets();
    }
    setDashboardWidgetsLoading(false);
  };

  const getDashboardWidgets = async () => {
    setDashboardWidgetsLoading(true);
    const response: DynamicDashboardAPI = await getWidgets();
    const dataJson = response.data.dataJson;
    if (
      response &&
      response?.status === 200 &&
      (dataJson === "string" || dataJson === "[]")
    ) {
      setDashboardWidgets({
        title1: "Layout",
        title2: "Layout",
        title3: "Layout",
        selectedType: "init",
        widget1: initialWidgets1,
        widget2: initialWidgets2,
        widget3: [],
      });
      setDashboardWidgetsLoading(false);
      return;
    } else {
      setDashboardWidgets(JSON.parse(dataJson));
    }
    setDashboardWidgetsLoading(false);
  };

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      getDashboardWidgets();
    }
  }, [auth]);

  useEffect(() => {
    (async () => {
      if (auth.isAuthenticated) {
        const response: any = await getUserInfo();
        if (response.status === 200) {
          setUser(response.data);
          setuserRole(response.data.role);
        }
      }
    })();
  }, [auth]);

  useEffect(() => {
    if (userRole) {
      if (userRole.includes("admin")) {
        setuserRole("admin");
      } else {
        setuserRole("user");
      }
    }
  }, [userRole]);

  useEffect(() => {
    if (window.devicePixelRatio === 1.25) {
      setIsScaled(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("dateTime", dateTime);
  }, [dateTime]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect({ state: window.location.href });
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading)
    return (
      <div className="bg-black fixed inset-0 grid place-content-center">
        <Loader />
      </div>
    );

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }
  if (auth.error) {
    return (
      <Router>
        <ErrorPage />
      </Router>
    );
  }
  if (!auth.isAuthenticated) {
    return <div>Unable to log in</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <AppContext.Provider
        value={{
          isLoggedIn,
          setIsLoggedIn,
          user,
          setUser,
          dateTime,
          setDateTime,
          userRole,
          setuserRole,
          getDashboardWidgets,
          dashboardWidgets,
          setDashboardWidgets,
          dashboardWidgetsLoading,
          updateDashboardWidgets,
          token,
          role,
          name,
          email,
          skype,
          id,
          isScaled,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }

  return <button onClick={() => void auth.signinRedirect({ state: window.location.href })}>Log in</button>;
};

export default function useApp() {
  return useContext(AppContext);
}
