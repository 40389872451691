import resetTagService from "../domain/resetTagService";
import deleteTagService from "../domain/deleteTagService";
import deleteExpressionService from "../domain/deleteExpreessionService";
import deleteTrainService from "../domain/deleteTrainService";
import deleteEquipmentService from "../domain/deleteEquipmentService";
import deleteUnitService from "../domain/deleteUnitService";
import deleteTicketService from "../domain/deleteTicketService";
import deleteAlarmService from "../domain/deleteAlarmService";
import deleteAllAlarmService from "../domain/deleteAllAlarmService";
import rejectTicketService from "../domain/rejectTicketService";
import unmapDoc from "../domain/unmapDoc";
import deleteDoc from "../domain/deleteDoc";
import deleteFiles from "../domain/deleteFiles";
import deleteUnityWidget from "../domain/deleteUnityWidget";

const dangerModalConfig = {
  tags: {
    title: 'tag',
    reset: {
      action: resetTagService,
    },
    delete: {
      action: deleteTagService,
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  },
  expressions: {
    title: 'expression',
    reset: {
      action: null,
    },
    delete: {
      action: deleteExpressionService,
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  },
  trains: {
    title: 'trian',
    reset: {
      action: null,
    },
    delete: {
      action: deleteTrainService,
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  },
  units: {
    title: 'unit',
    reset: {
      action: null,
    },
    delete: {
      action: deleteUnitService,
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  },
  equipments: {
    title: 'equipment',
    reset: {
      action: null,
    },
    delete: {
      action: deleteEquipmentService,
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  },
  tickets: {
    title: 'ticket',
    reset: {
      action: null,
    },
    delete: {
      action: deleteTicketService,
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: rejectTicketService,
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  },
  alarms: {
    title: 'alarm',
    reset: {
      action: null,
    },
    delete: {
      action: deleteAlarmService
    },
    deleteAll: {
      action: deleteAllAlarmService
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  },
  docs: {
    title: 'Docs',
    reset: {
      action: null,
    },
    delete: {
      action: deleteDoc
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: unmapDoc
    }
  },
  files: {
    title: 'files',
    reset: {
      action: null,
    },
    delete: {
      action: deleteFiles
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  },
  widgets: {
    title: 'files',
    reset: {
      action: null,
    },
    delete: {
      action: deleteUnityWidget
    },
    deleteAll: {
      action: null
    },
    reject: {
      action: null
    },
    close: {
      action: null
    },
    unmap: {
      action: null
    }
  }
};

export default dangerModalConfig;