import React, { useCallback, useContext, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import WebGLContext, { IChartData, IBubbleDetails } from "./WebGlContext";
import useSignalR from "hooks/useSignalR";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import {
  getNotes,
  updateBubblePosition as apiUpdateBubblePosition,
  getLabelsMeta,
  updateLabelPos,
  getAllWidgets,
  getWidget,
  updateWidgetPosition,
  deleteWidget,
  deleteBubble,
  deleteLabel,
  deleteNote,
  getWidgetsData,
  getWidgetsMeta
} from "api/unity";
import { getPointCloudImages } from "api/pointCloud";
import { SplashScreen } from "./SplashScreen";
import Pin from "pages/Dashboard/pinnedWidgets/Pin";
import { usePins } from "hooks/usePins";
import SideButtons from "./SideButtons";
import DiscreteSlider from "components/widgets/Slider/Slider";
import useUnity from "hooks/UseUnity";
import { LuChevronRight } from "react-icons/lu";
import UnityWidgetContainer from "components/UnityWidgetContainer/UnityWidgetContainer";
import Charts from "./Charts";
import Note from "./Note";
import Label from "./Label";
import { equipmentProps, unitsProps } from "types/tagexpressiontypes";
import Bubble from "./Bubble";
import { getData } from "./domain/getBubbleNotesUnitsEquipments";
import useData from "hooks/useData";
import { TagExpressionHandler } from "./domain/GetUnitEquipmentforTagsExpressions";
import { ModalContext } from "hooks/ModalProvider";
import { getFiles } from "api/fileManager";
import { PreviewFiles } from "components/shared/PreviewFiles";
import Widget from "./Widget";
import Modal from "components/modal/Modal";

const WebGL: React.FC = () => {
  function handleCacheControl(url: any) {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  const {
    unityProvider,
    sendMessage,
    isLoaded,
  } = useUnityContext({
    loaderUrl: window.vuplex ? "" : "Build/Unity/WebGL.loader.js",
    dataUrl: window.vuplex ? "" : "Build/Unity/WebGL.data",
    frameworkUrl: window.vuplex ? "" : "Build/Unity/WebGL.framework.js",
    codeUrl: window.vuplex ? "" : "Build/Unity/WebGL.wasm",
    cacheControl: handleCacheControl,
    streamingAssetsUrl: window.vuplex ? "" : "Build/Unity/StreamingAssets",
  });
  const { unityPins, unpinAllWidgets } = usePins();
  const { unityDataHandler } = useSignalR();
  const { TimelineShown } = useUnity();
  const { modalType } = useContext(ModalContext);

  const {
    getEquipmentInfo,
    getTagsInfo,
    pastDate
  } = useData();

  const [chartData, setChartData] = useState<IChartData[] | null>(null);
  const [bubbleDetails, setBubbleDetails] = useState<IBubbleDetails | null>(
    null
  );
  const [isMovementDisabled, setIsMovementDisabled] = useState(0);
  const [rerenderBubble, setRenderBubble] = useState(false);
  const [bubbleModalOpen, setBubbleModalOpen] = useState(false);
  const [bubblePopUp, setBubblePopUp] = useState<any>(null);
  const [chartType, setChartType] = useState<any>(null);
  const [notePopup, setNotePopup] = useState<any>(null);
  const [popupNoteId, setNoteId] = useState<number | null>(null);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [popupBubbleId, setBubbleId] = useState<number | null>(null);
  const [rerenderBubbleData, setRenderBubbleData] = useState(false);
  const [LabelPopup, setLabelPopup] = useState<any>(null);
  const [popupLabelId, setLabelId] = useState<number | null>(null);
  const [LabelModalOpen, setLabelModalOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoader, setModalLoader] = useState(true);
  const [ticketTags, updateTicketTags] = useState<number[]>([]);
  const [showPins, setShowPins] = useState(true);
  const [showLogo, setShowLogo] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(
    "Loading data from the network"
  );
  const [showPinned, setShowPinned] = useState(false);
  // Data
  const [allBubbles, setAllBubbles] = useState<any>(null);
  const [bubblesMeta, setBubblesMeta] = useState<any>(null);
  const [allLabels, setAllLabels] = useState<any>(null);
  const [equipments, setEquipments] = useState<equipmentProps[] | null>(null);
  const [units, setUnits] = useState<unitsProps[] | null>(null);
  const [allNotes, setAllNotes] = useState<any>(null);
  const [widgetData, setWidgetData] = useState({
    unitId: null,
    equipmentId: null,
    widgets: [],
    name: "",
    desc: "",
    x: 0,
    y: 0,
    z: 0
  });
  const [allWidgets, setAllWidgets] = useState<any>([]);
  const [selectedWidget, setSelectedWidget] = useState<number | null>(null);

  interface bubbleAllDetails {
    bubbletype: string;
    unitId: number | null;
    equipmentId: number | null;
  }

  const [showSideMenu, setShowMenu] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [unpinConfirmation, setUnpinConfirmation] = useState(false);
  const [bubbleAllDetails, setbubbleAllDetails] = useState<bubbleAllDetails | null>(null);
  const [pointCloudId, setPointCloudId] = useState<number | null>(null);
  const [picturesDetails, setPicturesDetails] = useState<any[]>([]);
  const [pointCloudPicId, setPointCloudPicId] = useState<any>(null);
  const [pointCloudPicData, setPointCloudPicData] = useState<any>({
    uniqueId: "",
    fileName: "",
    data: ""
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pictureLoading, setPictureLoading] = useState(false);
  const [minPicId, setMinPicId] = useState<null | number>(null);
  const [maxPicId, setMaxPicId] = useState<null | number>(null);
  const [tagsIds, setTagsIds] = useState([]);
  const [expressionIds, setExpressionsIds] = useState([]);
  const [widgetCoordinates, setWidgetCoordinates] = useState<number[]>([]);
  const [widgetModalMode, setWidgetModalMode] = useState<'none' | 'create' | 'modify' | 'delete'>('none');
  const [widgetPositionUpdate, setWidgetPositionUpdate] = useState<any>(null);
  const [itemToDelete, setItemToDelete] = useState<{ item: string, id: number | null }>({ item: '', id: null });

  function sendBubbleData(reload: boolean) {
    if (window.vuplex) {
      window.vuplex.postMessage(JSON.stringify({ object: "MainBubble", type: "ProcessHub.DynamicBubble.DynamicBubbleController", method: "SetBubbleData", arguments: [JSON.stringify(allBubbles)] }));
    }
  }

  useEffect(() => {
    if (selectedWidget !== null) {
      const getWidgetData = async (id: number) => {
        const response = await getWidget(id);
        if (response.status === 200) {
          setWidgetData({ ...response.data, id });
          setWidgetModalMode('modify');
        }
        else {
          console.log('error fetching widget');
        }
      };
      getWidgetData(selectedWidget);
    }
  }, [selectedWidget]);

  useEffect(() => {
    if (window.vuplex) {
      const message = JSON.stringify({ object: "__S:ReactInputBlocker__", type: "ReactInputBlocker", method: "SetBlockState", arguments: [isMovementDisabled] });
      window.vuplex.postMessage(message);
    }
  }, [isMovementDisabled]);

  useEffect(() => {
    if (modalType === 'gantt' || modalType === 'settings') {
      setShowMenu(false);
    }
  }, [modalType]);

  useEffect(() => {
    if (widgetModalMode === 'none') {
      setIsMovementDisabled(0);
      const getWidgets = async () => {
        const response = await getAllWidgets();
        if (response.status === 200) {
          setAllWidgets(response.data);
        } else {
          //error handling will be here
        }
      };
      getWidgets();
      setSelectedWidget(null);
    } else {
      setIsMovementDisabled(1);
    }
  }, [widgetModalMode]);

  useEffect(() => {
    if (allWidgets.length > 0) {
      window.vuplex.postMessage(
        JSON.stringify(
          {
            object: "MainWidget",
            type: "ProcessHub.DynamicWidget.DynamicWidgetController",
            method: "SetWidgetList",
            arguments: [JSON.stringify(allWidgets)]
          }
        )
      );
    }
    getWidgetsInfo();
  }, [allWidgets]);

  useEffect(() => {
    if (modalOpen || noteModalOpen || LabelModalOpen || bubbleModalOpen) {
      const message = JSON.stringify({ object: "__S:ReactInputBlocker__", type: "ReactInputBlocker", method: "SetBlockState", arguments: [1] });
      window.vuplex.postMessage(message);
    }
    else {
      const message = JSON.stringify({ object: "__S:ReactInputBlocker__", type: "ReactInputBlocker", method: "SetBlockState", arguments: [0] });
      window.vuplex.postMessage(message);
    }
  }, [modalOpen, noteModalOpen, LabelModalOpen, bubbleModalOpen]);

  useEffect(() => {
    if (window.vuplex) {
      (async () => {
        const data: any = await getData();
        setBubblesMeta(data.BubblesMeta);
        setAllBubbles(data.BubblesData);
        setAllNotes(data.Notes);
        setEquipments(data.Equipments);
        setUnits(data.Units);
        setAllLabels(data.LabelsMeta);

        window.vuplex.postMessage(JSON.stringify({ object: "MainNote", type: "ProcessHub.DynamicNote.DynamicNoteController", method: "SetNotesList", arguments: [JSON.stringify(allNotes)] }));
        window.vuplex.postMessage(JSON.stringify({ object: "MainLabel", type: "ProcessHub.DynamicLabel.DynamicLabelController", method: "SetLabelsList", arguments: [JSON.stringify(allLabels)] }));
      })();
      getWidgetsInfo();
    }
  }, [window.vuplex]);

  const updateNotes = async () => {
    if (window.vuplex) {
      const notesResponse = await getNotes();
      if (notesResponse.status === 200) {
        setAllNotes(notesResponse.data);
      }
      window.vuplex.postMessage(JSON.stringify({ object: "MainNote", type: "ProcessHub.DynamicNote.DynamicNoteController", method: "SetNotesList", arguments: [JSON.stringify(allNotes)] }));
    }
  };

  const useDataFetcher = (ids: any, messageType: any, fetchDataFn: any) => {
    useEffect(() => {
      if (ids.length > 0 && window.vuplex) {
        const fetchData = async () => {
          const response = await fetchDataFn(ids, pastDate);
          if (response.status === 200) {
            sendMessage("DataManager", messageType, JSON.stringify(response.data));
          }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 60000);
        return () => clearInterval(intervalId);
      }
    }, [ids, fetchDataFn, messageType, pastDate, window]);
  };

  useDataFetcher(tagsIds, "SetTagsData", getTagData);
  useDataFetcher(expressionIds, "SetExpressionsData", getExpressionData);

  const updateLabels = async () => {
    if (window.vuplex) {
      const LabelsResponse = await getLabelsMeta();
      if (LabelsResponse.status === 200) {
        setAllLabels(LabelsResponse.data);
      }
      window.vuplex.postMessage(JSON.stringify({ object: "MainLabel", type: "ProcessHub.DynamicLabel.DynamicLabelController", method: "SetLabelsList", arguments: [JSON.stringify(allLabels)] }));
    }
  };

  useEffect(() => {
    setRenderBubble(true);
  }, [allBubbles]);

  useEffect(() => {
    if (window.vuplex && rerenderBubbleData) {
      sendBubbleData(false);
      setRenderBubbleData(false);
    }
  }, [rerenderBubbleData, sendBubbleData, window.vuplex]);

  function resumeUnity() {
    if (isLoaded) {
      sendMessage("Utilities", "ResumeGame");
    }
  }

  function pauseUnity() {
    sendMessage("Utilities", "PauseGameFromReact");
  }

  function bubbleCloseHandler() {
    setBubbleModalOpen(false);
    setBubblePopUp(null);
    setbubbleAllDetails(null);
    resumeUnity();
  }

  useEffect(() => {
    if (window.vuplex) {
      window.vuplex.postMessage(JSON.stringify({ object: "MainBubble", type: "ProcessHub.DynamicBubble.DynamicBubbleController", method: "SetBubbleList", arguments: [JSON.stringify(bubblesMeta)] }));
    }
  }, [window.vuplex]);

  useEffect(() => {
    if (window.vuplex && rerenderBubble) {
      sendBubbleData(true);
      setRenderBubble(false);
    }
  }, [rerenderBubble, sendBubbleData, window.vuplex]);

  useEffect(() => {
    setRenderBubbleData(true);
  }, [units]);

  useEffect(() => {
    if (window.vuplex) {
      if (allNotes) {
        const notesCollection = allNotes.map((eachNote: any) => {
          const { id, x, y, z, header, note } = eachNote;
          return { id, x, y, z, header, note };
        });
        window.vuplex.postMessage(JSON.stringify({ object: "MainNote", type: "ProcessHub.DynamicNote.DynamicNoteController", method: "SetNotesList", arguments: [JSON.stringify(notesCollection)] }));
      } else {
        window.vuplex.postMessage(JSON.stringify({ object: "MainNote", type: "ProcessHub.DynamicNote.DynamicNoteController", method: "SetNotesList", arguments: [JSON.stringify(allNotes)] }));
      }
    }
  }, [allNotes, window.vuplex]);

  useEffect(() => {
    if (window.vuplex) {
      if (allLabels) {
        const LabelsCollection = allLabels.map((eachLabel: any) => {
          const { id, x, y, z, prefabId, prefabParentId, name } = eachLabel;
          return { id, x, y, z, prefabId, prefabParentId, name };
        });
        window.vuplex.postMessage(JSON.stringify({ object: "MainLabel", type: "ProcessHub.DynamicLabel.DynamicLabelController", method: "SetLabelsList", arguments: [JSON.stringify(LabelsCollection)] }));
      } else {
        window.vuplex.postMessage(JSON.stringify({ object: "MainLabel", type: "ProcessHub.DynamicLabel.DynamicLabelController", method: "SetLabelsList", arguments: [JSON.stringify(allLabels)] }));
      }
    }
  }, [allLabels, window.vuplex]);

  useEffect(() => {
    if (window.vuplex) {
      if (picturesDetails.length > 0) {
        setMinPicId(picturesDetails[0].id);
        setMaxPicId(picturesDetails[picturesDetails.length - 1].id);
        window.vuplex.postMessage(
          JSON.stringify({
            object: "__ScansManager__",
            type: "Scans.ScansManager",
            method: "SetCamerasData",
            arguments: [JSON.stringify(picturesDetails)],
          })
        );
      }
    }
  }, [picturesDetails, window.vuplex]);

  const useUnityDataEffect = (selectedData: any, apiCall: any, method: any) => {
    useEffect(() => {
      if (selectedData) {
        const fetchData = async () => {
          const response = await apiCall(selectedData, pastDate);
          const processedData = response.data;
          const message = JSON.stringify({ object: "DataManager", type: "DataManager", method, arguments: [JSON.stringify(processedData)] });
          window.vuplex.postMessage(message);
        };

        fetchData();

        const intervalId = setInterval(fetchData, 15000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }, [selectedData, method, pastDate]);
  };

  useUnityDataEffect(tagsIds, getTagData, "SetTagsData");
  useUnityDataEffect(expressionIds, getExpressionData, "SetExpressionsData");

  useEffect(() => {
    if (pointCloudId !== null) {
      const getPointCloudPics = async () => {
        if (pointCloudId) {
          const response = await getPointCloudImages(pointCloudId);
          if (response.status === 200) {
            setPicturesDetails(response.data);
          }
        }
      };
      getPointCloudPics();
    }
  }, [pointCloudId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (pointCloudPicId !== null) {
      setPictureLoading(true);
      const getPicture = async () => {
        try {
          if (pointCloudPicId) {
            const response = await getFiles(pointCloudPicId, { signal });
            if (response.status === 200) {
              setPointCloudPicData(response.data);
              setPointCloudId(null);
              setPictureLoading(false);
            }
          }
        } catch (error: any) {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted');
          } else {
            console.error('Error fetching picture:', error);
          }
        }
      };
      getPicture();

      window.vuplex.postMessage(
        JSON.stringify({
          objectName: "__ScansManager__",
          type: "Scans.ScansManager",
          method: "SetFileMetaIdAsViewed",
          arguments: [JSON.stringify(pointCloudPicId)],
        })
      );
    }
    return () => {
      controller.abort();
    };
  }, [pointCloudPicId]);

  function createBubble(arr: number[]) {
    const bubbleDetails = {
      x: arr[0],
      y: arr[1],
      z: arr[2],
      unitId: null,
      equipmentId: null,
    };
    setBubblePopUp(bubbleDetails);
    setBubbleModalOpen(true);
    setBubbleId(null);
  }

  function createNote(arr: number[]) {
    const noteDetails = {
      x: arr[0],
      y: arr[1],
      z: arr[2],
    };
    setNotePopup(noteDetails);
    setNoteId(null);
    setNoteModalOpen(true);
  }

  function createLabel(arr: number[]) {
    const LabelDetails = {
      x: arr[0],
      y: arr[1],
      z: arr[2],
    };
    setLabelPopup(LabelDetails);
    setLabelId(null);
    setLabelModalOpen(true);
  }

  async function updateBubblePosition(bubbles: any) {
    if (bubbles) {
      const data = JSON.parse(bubbles);
      const response: any = await apiUpdateBubblePosition(data);
      if (response.status === 200) {
        refreshUnityData();
      }
    }
  }

  function configureBubbleHandler(bubbleId: any) {
    if (bubbleId) {
      setBubblePopUp(null);
      setBubbleModalOpen(true);
      setBubbleId(bubbleId);
    }
  }

  function openNote(noteId: number) {
    setNotePopup(null);
    setNoteId(noteId);
    setNoteModalOpen(true);
  }

  function openLabel(LabelId: number) {
    setLabelPopup(null);
    setLabelId(LabelId);
    setTimeout(() => {
      setLabelModalOpen(true);
    }, 500);
  }


  async function updateLabelPosition(labels: any) {
    if (labels) {
      const response = await updateLabelPos(labels);
      if (response.status === 200) {
        const labelsMetaResponse = await getLabelsMeta();
        if (labelsMetaResponse.status === 200) {
          setAllLabels(labelsMetaResponse.data);
        }
      }
    }
  }

  const tagsAndBubbleConfigHandler: any = useCallback(
    async (bubbleId: number, equipmentId: number, type: string) => {
      const bubbleTagDetails: any = await TagExpressionHandler(
        bubbleId,
        equipmentId,
        type,
        "tag"
      );

      if (type === "unconfigured") {
        setSelectedUnit(null);
        configureBubbleHandler(bubbleId);
      } else {
        setbubbleAllDetails({ bubbletype: type, unitId: bubbleTagDetails.unitId, equipmentId: type == "equipment" ? equipmentId : null });
        setSelectedUnit(bubbleTagDetails.unitId);
        setBubbleDetails(bubbleTagDetails.details);
        if (
          bubbleTagDetails.collections &&
          bubbleTagDetails.collections.length > 0
        ) {
          setChartData(bubbleTagDetails.collections);
          setModalOpen(true);
          setModalLoader(false);
          setBubbleId(bubbleId);
        } else {
          configureBubbleHandler(bubbleId);
        }
      }
    },
    [getTagsInfo, getEquipmentInfo, configureBubbleHandler, equipments]
  );

  async function expressionsHandler(bubbleId: number, assertId: number, type: string) {
    const bubbleExpressionDetails: any = await TagExpressionHandler(
      bubbleId,
      assertId,
      type,
      "expression"
    );
    setSelectedUnit(bubbleExpressionDetails.unitId);
    setBubbleDetails(bubbleExpressionDetails.details);

    setbubbleAllDetails({ bubbletype: type, unitId: bubbleExpressionDetails.unitId, equipmentId: type == "equipment" ? assertId : null });
    if (
      bubbleExpressionDetails.collections &&
      bubbleExpressionDetails.collections.length > 0
    ) {
      setChartData(bubbleExpressionDetails.collections);
      setModalOpen(true);
      setModalLoader(false);
      setBubbleId(bubbleId);
    } else {
      configureBubbleHandler(bubbleId);
    }
  }

  const noteCloseHandler = useCallback(() => {
    setNoteModalOpen(false);
    resumeUnity();
  }, [resumeUnity]);

  const LabelCloseHandler = useCallback(() => {
    setLabelModalOpen(false);
    resumeUnity();
  }, [resumeUnity]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    resumeUnity();
  }, [resumeUnity]);

  const handleModalLoader = useCallback(() => {
    pauseUnity();
    setModalLoader(false);
  }, [pauseUnity]);

  const ticketTagsHandler = useCallback((tagId: number) => {
    updateTicketTags((state) => {
      const newList = [...state];
      newList.push(tagId);
      return newList;
    });
  }, []);

  const onLeftArrowClick = () => {
    if (minPicId !== null) {
      if (pointCloudPicId > minPicId) {
        setPointCloudPicData({
          uniqueId: "",
          fileName: "",
          data: ""
        });
        setPointCloudPicId((prev: number) => prev - 1);
      }
    }
  };

  const onRightArrowClick = () => {
    if (maxPicId !== null) {
      if (pointCloudPicId < maxPicId) {
        setPointCloudPicData({
          uniqueId: "",
          fileName: "",
          data: ""
        });
        setPointCloudPicId((prev: number) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (isLoaded) {
      setShowLogo(true);
      setTimeout(() => {
        setShowLogo(false);
      }, 5000);
    }
  }, [isLoaded]);

  function sendCanvasVisibility(state: number) {
    window.vuplex.postMessage(JSON.stringify({ object: "CanvasVisibility", type: "CanvasVisibility", method: "SetStatus", arguments: [state] }));
  }


  useEffect(() => {
    if (window.vuplex && location.pathname === '/3d-dashboard') {
      sendCanvasVisibility(1);
      return () => {
        sendCanvasVisibility(0);
      };
    }
    else {
      return () => {
        sendCanvasVisibility(0);
      };
    }
  }, [window.vuplex, location.pathname]);

  useEffect(() => {
    if (!isModalOpen) {
      setPictureLoading(false);
      setPointCloudPicId(null);
      setPointCloudPicData({
        uniqueId: "",
        fileName: "",
        data: ""
      });
      setIsMovementDisabled(0);
    }
  }, [isModalOpen]);

  const getWidgetsInfo = async () => {
    const widgetsData: any = await getWidgetsData();
    const widgetsMeta: any = await getWidgetsMeta();
    if (widgetsData.status === 200) {
      window.vuplex.postMessage(
        JSON.stringify(
          {
            object: "MainWidget",
            type: "ProcessHub.DynamicWidget.DynamicWidgetController",
            method: "SetWidgetData",
            arguments: [JSON.stringify(widgetsData.data)]
          }
        )
      );
    }
    if (widgetsMeta.status === 200) {
      window.vuplex.postMessage(
        JSON.stringify(
          {
            object: "MainWidget",
            type: "ProcessHub.DynamicWidget.DynamicWidgetController",
            method: "SetWidgetMeta",
            arguments: [JSON.stringify(widgetsMeta.data)]
          }
        )
      );
    }
  };


  const refreshUnityData = async () => {
    const data = await getData(true);
    setBubblesMeta(data.BubblesMeta);
    setAllBubbles(data.BubblesData);
    if (window.vuplex) {
      window.vuplex.postMessage(JSON.stringify({ object: "MainBubble", type: "ProcessHub.DynamicBubble.DynamicBubbleController", method: "SetBubbleList", arguments: [JSON.stringify(bubblesMeta)] }));
      window.vuplex.postMessage(JSON.stringify({ object: "MainBubble", type: "ProcessHub.DynamicBubble.DynamicBubbleController", method: "SetBubbleData", arguments: [JSON.stringify(allBubbles)] }));
    }
  };

  useEffect(() => {
    if (window.vuplex) {
      sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
      window.vuplex.postMessage(JSON.stringify({ object: "MainBubble", type: "ProcessHub.DynamicBubble.DynamicBubbleController", method: "SetBubbleList", arguments: [JSON.stringify(bubblesMeta)] }));
      window.vuplex.postMessage(JSON.stringify({ object: "MainBubble", type: "ProcessHub.DynamicBubble.DynamicBubbleController", method: "SetBubbleData", arguments: [JSON.stringify(allBubbles)] }));
    }
  }, [allBubbles, bubblesMeta, allNotes]);

  function addMessageListener() {
    window.vuplex.addEventListener("message", async function (event: { data: any }) {
      const tjson = event.data;
      const parsedInfo = JSON.parse(tjson);
      if (parsedInfo.methodName === 'TriggerOnLeftClick') {
        const tagsAndBubblesInfo = JSON.parse(parsedInfo.message);
        setbubbleAllDetails(tagsAndBubblesInfo);
        tagsAndBubbleConfigHandler(tagsAndBubblesInfo.id, tagsAndBubblesInfo.AssertId, tagsAndBubblesInfo.AssertType);
      }
      if (parsedInfo.methodName === 'TriggerOnRightClick') {
        const expressions = JSON.parse(parsedInfo.message);
        setbubbleAllDetails(expressions);
        expressionsHandler(expressions.Id, expressions.AssertId, expressions.AssertType);
      }
      if (parsedInfo.methodName === 'NewBubbleAdded') {
        const bubblePoints = JSON.parse(parsedInfo.message);
        createBubble(bubblePoints);
      }
      if (parsedInfo.methodName === 'UpdateBubblePosition') {
        const newBubblePosition = JSON.parse(parsedInfo.message);
        updateBubblePosition(newBubblePosition);
      }
      if (parsedInfo.methodName === 'NewNoteAdded') {
        const newNote = JSON.parse(parsedInfo.message);
        createNote(newNote);
      }
      if (parsedInfo.methodName === 'openNote') {
        const noteToOpen = JSON.parse(parsedInfo.message);
        openNote(noteToOpen);
      }
      if (parsedInfo.methodName === 'NewLabelAdded') {
        const newLabel = JSON.parse(parsedInfo.message);
        createLabel(newLabel);
      }
      if (parsedInfo.methodName === 'openLabel') {
        const labelToOpen = JSON.parse(parsedInfo.message);
        openLabel(labelToOpen);
      }
      if (parsedInfo.methodName === 'UpdateLabelPosition') {
        const updatedLabel = JSON.parse(parsedInfo.message);
        updateLabelPosition(updatedLabel);
      }
      if (parsedInfo.methodName === 'GetCamerasByScanID') {
        const cameraId = JSON.parse(parsedInfo.message);
        setPointCloudId(Number(cameraId));
      }
      if (parsedInfo.methodName === 'UnityOpenImageRequest') {
        const pictureId = JSON.parse(parsedInfo.message);
        if (pointCloudPicId === null) {
          setPointCloudPicId(Number(pictureId));
          setIsMovementDisabled(1);
          setIsModalOpen(true);
        }
      }
      if (parsedInfo.methodName === 'UnityTagsRequest') {
        if (parsedInfo.message.length > 0) {
          const ids = JSON.parse(parsedInfo.message);
          setTagsIds(ids);
        }
      }
      if (parsedInfo.methodName === 'UnityExpressionsRequest') {
        if (parsedInfo.message.length > 0) {
          const ids = JSON.parse(parsedInfo.message);
          setExpressionsIds(ids);
        }
      }
      if (parsedInfo.methodName === 'NewWidgetAdded') {
        if (parsedInfo.message.length > 0) {
          const coordinates = JSON.parse(parsedInfo.message);
          setSelectedWidget(null);
          setWidgetCoordinates(coordinates);
          setWidgetModalMode('create');
        }
      }
      if (parsedInfo.methodName === 'OpenWidget') {
        if (parsedInfo.message.length > 0) {
          const id = JSON.parse(parsedInfo.message);
          setSelectedWidget(id);
        }
      }
      if (parsedInfo.methodName === 'UpdateWidgetPosition') {
        if (parsedInfo.message.length > 0) {
          const updatedWidgetPosition = JSON.parse(parsedInfo.message);
          setWidgetPositionUpdate(updatedWidgetPosition);
        }
      }
      if (parsedInfo.methodName === 'DeleteWidget') {
        if (parsedInfo.message.length > 0) {
          const itemToDelete = JSON.parse(parsedInfo.message);
          setItemToDelete({ item: 'widget', id: itemToDelete });
        }
      }
      if (parsedInfo.methodName === 'DeleteBubble') {
        if (parsedInfo.message.length > 0) {
          const itemToDelete = JSON.parse(parsedInfo.message);
          setItemToDelete({ item: 'bubble', id: itemToDelete });
        }
      }
      if (parsedInfo.methodName === 'DeleteLabel') {
        if (parsedInfo.message.length > 0) {
          const itemToDelete = JSON.parse(parsedInfo.message);
          setItemToDelete({ item: 'label', id: itemToDelete });
        }
      }
      if (parsedInfo.methodName === 'DeleteNote') {
        if (parsedInfo.message.length > 0) {
          const itemToDelete = JSON.parse(parsedInfo.message);
          setItemToDelete({ item: 'note', id: itemToDelete });
        }
      }
    });
  }

  useEffect(() => {
    if (itemToDelete && itemToDelete.id !== null) {  // Check if itemToDelete and id are valid
      const removeItem = async () => {
        const itemsConfig: Record<string, (id: any) => Promise<any>> = {
          widget: deleteWidget,
          bubble: deleteBubble,
          label: deleteLabel,
          note: deleteNote
        };

        const api = itemsConfig[itemToDelete.item];
        if (api) {
          try {
            const response = await api(itemToDelete.id);
            if (response.status === 200) {
              setItemToDelete({ item: '', id: null });
            } else {
              console.error(`${itemToDelete.item} deletion failed with status: ${response.status}`);
            }
          } catch (error) {
            console.error(`Error deleting ${itemToDelete.item}:`, error);
          }
        } else {
          console.warn(`No delete function found for item type: ${itemToDelete.item}`);
        }
      };

      removeItem();
    }
  }, [itemToDelete]);

  useEffect(() => {
    if (widgetPositionUpdate !== null) {
      const updateWidgetPosotionApi = async () => {
        const response = await updateWidgetPosition(widgetPositionUpdate);
        if (response.status === 200) {
          setWidgetPositionUpdate(null);
        }
        else {
          console.error('widget not updated');
        }
      };
      updateWidgetPosotionApi();
    }
  }, [widgetPositionUpdate]);

  useEffect(() => {
    if (window.vuplex) {
      addMessageListener();
    }
    else {
      window.addEventListener('vuplexready', addMessageListener);
    }
  }, []);

  useEffect(() => {
    if (widgetCoordinates.length > 0) {
      setWidgetData((prev: any) => ({
        ...prev,
        x: widgetCoordinates[0],
        y: widgetCoordinates[1],
        z: widgetCoordinates[2]
      }));
    }
  }, [widgetCoordinates]);

  return (
    <WebGLContext.Provider
      value={{
        resumeUnity,
        pauseUnity,
        chartData,
        bubbleDetails,
        handleModalLoader,
        handleCloseModal,
        modalOpen,
        modalLoader,
        ticketTags,
        ticketTagsHandler,
        popupBubbleId,
        configureBubbleHandler,
        chartType,
        setChartType,
        refreshUnityData,
        bubbleAllDetails
      }}
    >
      {modalOpen && chartData && <Charts setModalOpen={setModalOpen} />}

      {bubbleModalOpen && (
        <Bubble
          bubbleId={popupBubbleId}
          modalOpen={bubbleModalOpen}
          bubbleDetails={bubblePopUp}
          closeHandler={bubbleCloseHandler}
          updateBubbles={refreshUnityData}
          units={units}
          equipments={equipments}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
          selectedUnit={selectedUnit}
        />
      )}

      {widgetModalMode !== 'none' && <Widget
        units={units}
        equipments={equipments}
        widgetData={widgetData}
        setWidgetData={setWidgetData}
        widgetModalMode={widgetModalMode}
        setWidgetModalMode={setWidgetModalMode}
      />}

      {noteModalOpen && (
        <Note
          noteId={popupNoteId}
          modalOpen={noteModalOpen}
          noteDetails={notePopup}
          closeHandler={noteCloseHandler}
          updateNotes={updateNotes}
          getData={getData}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
        />
      )}

      {LabelModalOpen && (
        <Label
          LabelId={popupLabelId}
          modalOpen={LabelModalOpen}
          LabelDetails={LabelPopup}
          closeHandler={LabelCloseHandler}
          updateLabels={updateLabels}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
          allLabels={allLabels}
        />
      )}

      {showPins && unityPins && unityPins.length > 0 && (
        <div className="absolute right-0 2xl:right-2 bottom-0 2xl:bottom-3 rounded-md 3xl:p-3 p-1 flex flex-col gap-1 2xl:gap-3">
          {unityPins.slice(0, 6).map((pin: any) => (
            <Pin key={pin.id} data={pin} unityPin />
          ))}
          <div className="flex items-end justify-end h-max w-full">
            {!unpinConfirmation && <button className="btn-danger" onClick={() => setUnpinConfirmation(true)}>Unpin All Tags</button>}
            {unpinConfirmation &&
              <div className="flex gap-5">
                <button className="btn-sec" onClick={() => setUnpinConfirmation(false)}>Keep</button>
                <button className="btn-danger" onClick={() => {
                  unpinAllWidgets(2);
                  setUnpinConfirmation(false);
                }}>Remove</button>
              </div>}
          </div>
        </div>
      )}
      {pointCloudPicId && isModalOpen && (
        <div className="h-screen w-full flex items-center justify-center align-middle relative">
          <div className="relative">
            <PreviewFiles
              data={pointCloudPicData.data}
              fileName={pointCloudPicData.fileName}
              type='unity'
              fileType='png'
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              onLeftArrowClick={onLeftArrowClick}
              onRightArrowClick={onRightArrowClick}
            />
          </div>
        </div>
      )}

      <div>
        <div id="unity-container">
          <SplashScreen
            open={!isLoaded || showLogo}
            logo={true}
            status={loadingStatus}
          />
          <Unity
            unityProvider={unityProvider}
            className="unityApp"
            style={{
              width: "100vw",
              height: "99.2vh",
              visibility: isLoaded || showLogo ? "visible" : "hidden",
            }}
          />

        </div>
      </div>

      {<div className="absolute z-90 left-0 bottom-[620px] top-[135px] cursor-pointer">
        <div
          onClick={() => setShowMenu(!showSideMenu)}
          className="cursor-pointer duration-300"
        >
          {!showSideMenu && <div className="h-[40px] w-[30px] rounded-md -ml-1 pt-3 px-2" style={{ background: 'rgb(41,44,38,0.75)' }}><LuChevronRight size="15" /></div>}
        </div>
      </div>}
      {TimelineShown &&
        <div className="absolute w-full h-max left-0 top-24 flex items-center justify-center">
          <div className="z-30">
            <DiscreteSlider />
          </div>
        </div>
      }

      <div>
        <SideButtons closeMenu={() => setShowMenu(false)} showSideMenu={showSideMenu} />
      </div>

      <div className="absolute top-20 right-0 w-max h-max">
        <UnityWidgetContainer mockData={44} name='Overall Progress' units='%' />
      </div>

      {!showPinned && isLoaded && (
        <div
          onClick={() => setShowPinned(true)}
          className="cursor-pointer absolute right-2 top-20 bg-black/40 rounded-md px-3 py-1 flex flex-col gap-3"
        >
          Show Pinned Tags
        </div>
      )}
      {showPinned && unityPins && (
        <div className="absolute right-2 bottom-20 bg-black/40 rounded-md p-3 flex flex-col gap-3">
          <div className="flex justify-end">
            <div
              onClick={() => setShowPinned(false)}
              className="my-2 cursor-pointer"
            >
              Hide Pinned Tags
            </div>
          </div>
          {unityPins.map((pin: any) => (
            <Pin key={pin.id} data={pin} unityPin />
          ))}
        </div>
      )}
    </WebGLContext.Provider>
  );
};

export default WebGL;